<template>
	<header class="head" :class="{ 'full': isLogin }">
		<div class="head__redbox">
			<div class="head__logo">
				<svg viewBox="0 0 280.6 50">
					<use xlink:href="#logo--main" width="100%" height="100%"></use>
				</svg>
			</div>
			<div class="head__content">
				
			</div>
		</div>
		<div class="head__cart">
			<router-link :to="{ name: 'carrello.page'}" class="head__cart__icon">
				<span v-if="cart" class="head__cart__icon__badge">{{ cart }}</span>
				<svg viewbox="0 0 50 50">
					<use xlink:href="#icon--cart" width="100%" height="100%"></use>
				</svg>
			</router-link>
		</div>
	</header>	
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'Head',
	props: {
		page: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			isLogin: false,
			cart: null,
		}
	},
	watch: {
		page: {
			immediate: true,
			handler(newval, oldval) {
				this.init();
			}
		}
	},
	methods: {
		init() {
			if(this.page == 'login.page') {
				this.isLogin = true;
			} else {
				this.isLogin = false;
			}

			let localCart = JSON.parse(localStorage.getItem('cart'));
			if(localCart && localCart.length) {
				this.cart = localCart.length;
			}

		}	
	},
	mounted() {
		this.init();

		EventBus.$on('update-cart', cart => {
			if(cart) {
				this.cart = cart.length;
			}
		})
	}
}
</script>
<style lang="scss">
.head {
	position: fixed;
	top: 0;
	left: var(--sidebar);
	@extend %flexCenterBetween;
	@include size(calc(100% - var(--sidebar)),var(--head));
	z-index: 1000;
	&__redbox {
		@extend %flexCenterBetween;
		@include size(calc(100% - var(--cartbox)),100%);
		padding: calc(var(--minigutter) / 2) var(--minigutter);
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			background: $color-red;
			@include size(100%,100%);
			clip-path: polygon(0% 0%, 100% 0%, 98.4% 100%, 0% 100%);
			z-index: 0;
		}
	}
	&__logo {
		display: block;
		height: 80%;
		position: relative;
		z-index: 1;
		svg {
			display: block;
			@include size(100%,100%);
			fill: $color-white;
		}
	}
	&__cart {
		@extend %flexCenterCenter;
		@include size(var(--cartbox), 100%);
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--smallicon),var(--smallicon));
			position: relative;
			svg {
				display: block;
				@include size(100%,100%);
				fill: $color-black;
			}
			&__badge {
				position: absolute;
				top: 0;
				right: 0;
				@extend %flexCenterCenter;
				background: $color-fire;
				@include size(var(--minigutter),var(--minigutter));
				@extend %text-8;
				text-align: center;
				color: $color-white;
				border-radius: 100%;
				transform: translate(50%,-50%);
			}
		}
	}
	&.full {
		left: 0;
		.head__cart {
			display: none;
		}
	}
}
</style>