<template>
	<header id="head" class="headapp" :class="{'darkmode': darkmode}">
		<div class="headapp__left" :class="{ 'planthead': isPlant }">
			<span class="headapp__icon" :class="{ 'hide': isMain }" @click="goback">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--back-arrow" width="100%" height="100%"></use>
				</svg>
			</span>
			<span class="headapp__label" :class="{ 'hide': !isPlant }" v-html="plantName"></span>
		</div>
		<div class="headapp__right">
			<router-link :to="{ name: 'app.page' }" class="headapp__icon">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--qr" width="100%" height="100%"></use>
				</svg>
			</router-link>
			<router-link :to="{ name: 'cart.page' }" class="headapp__icon carticon">
				<span v-if="cart && cart.length" class="carticon__badge">
					{{ cart.length }}
				</span>
				<svg class="carticon__icon" viewBox="0 0 50 50">
					<use xlink:href="#icon--cart" width="100%" height="100%"></use>
				</svg>
			</router-link>
		</div>
	</header>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'Head',
	data() {
		return {
			isMain: true,
			cart: null,
			darkmode: false,
			isPlant: false,
			plantName: '',
		}
	},
	watch: {
		plantName: {
			immediate: true,
			handler(newval, oldval) {
				if(newval != oldval) {
					return newval;
				}
			}
		},
		'$route': {
			immediate: true,
			handler(newval, oldval) {
				if(this.$router.currentRoute.name != 'app.page') {
					this.isMain = false;
					this.darkmode = true;
				} else {
					this.isMain = true;
					this.darkmode = false;
				}

				if(this.$router.currentRoute.name == 'plant.page') {
					this.isPlant = true;
				} else {
					this.isPlant = false;
				}

				console.log(this.$router.currentRoute.name, this.darkmode);
			}
		},
		cart: {
			immediate: true,
			handler(newval, oldval) {
				return newval;
			}
		}
	},
	methods: {
		goback() {
			return this.$router.go(-1);
		},
		init() {
			this.cart = JSON.parse(localStorage.getItem('cart'));
		}
	},
	mounted() {
		this.init();
		EventBus.$on('cart-update', () => {
			this.cart = JSON.parse(localStorage.getItem('cart'));
		})
		
		EventBus.$on('update-plant-name', name => {
			this.plantName = name;
		})
		EventBus.$on('confirm-order', () => {
			this.cart = [];
		});
	}
}
</script>
<style lang="scss">
.headapp {
	position: fixed;
	top: 0;
	left: 0;
	@extend %flexCenterBetween;
	@include size(100%,var(--head));
	z-index: 1000;
	.content {
		@extend %flexCenterBetween;
		height: 100%;
	}
	&__left, &__right {
		height: 100%;
	}
	&__left {
		@extend %flexCenterStart;
		width: 73%;
		padding-left: var(--minigutter);
		&.planthead {
			@extend %flexCenterStart;
			background: $color-red;
			@include size(75%, var(--head));
			clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%);
			@extend %text-16;
			font-family: $font-oswald;
			color: $color-white;
			padding-right: var(--gutter);
			position: relative;
			z-index: 2;
			.headapp__icon svg {
				fill: $color-white !important;
			}
		}
	}
	&__right {
		@extend %flexCenterEnd;
		width: 27%;
		padding-right: var(--minigutter);
	}
	&__icon {
		@extend %icon;
		margin-left: var(--gutter);
		position: relative;
		svg {
			fill: $color-white;
		}
		&:first-of-type {
			margin: 0;
		}
		&.hide {
			display: none;
		}
		.carticon {
			&__badge {
				position: absolute;
				top: 0;
				right: 0;
				@extend %text-9;
				@extend %flexCenterCenter;
				background: $color-red;
				@include size(var(--badge),var(--badge));
				color: $color-white;
				font-weight: 700;
				border-radius: 100%;
				text-align: center;
				transform: translate(50%,-50%);
			}
		}
	}
	&__label {
		display: block;
		padding-left: var(--minigutter);
		&.hide {
			display: none;
		}
	}
	&.darkmode {
		.headapp {
			&__icon {
				svg {
					fill: $color-black;
				}
			}
		}
	}
}
</style>