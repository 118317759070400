<template>
	<main id="orderconfirmed" class="page-admin orderconfirmed">
		<div class="orderconfirmed__content">
			<div class="content">
				<div class="orderconfirmed__box">
					<span class="orderconfirmed__box__title" v-html="$t('admin.confirmed.title')"></span>
					<span class="orderconfirmed__box__text" v-html="$t('admin.confirmed.text')"></span>
				</div>
				<div class="orderconfirmed__ctas">
					<router-link :to="{ name: 'dashboard.page' }" class="orderconfirmed__btn">
						{{ $t('admin.confirmed.btns.list') }}
					</router-link>
					<router-link :to="{ name: 'neworder.page' }" class="orderconfirmed__btn">
						{{ $t('admin.confirmed.btns.new') }}
					</router-link>
				</div>
				<span class="content__bg">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--logo-e" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
		</div>
	</main>	
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';

export default {
	name: 'OrderConfirmed',
	data() {
		return {
			order: null,
		}
	},
	methods: {
		init() {
			EventBus.$emit('orderslist-updated');
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.orderconfirmed {
	height: 100vh;
	overflow: hidden;
	position: relative;
	&__content {
		@extend %flexStartBetween;
		width: 100%;
		height: calc(100% - ((var(--supergutter) * 1.5)));
		margin: calc(var(--supergutter) * 1.5) 0 0;
		overflow: auto;
		.content {
			background: $color-white;
			border-radius: var(--radius);
			box-shadow: -3px 3px 5px rgba($color-onyx, 0.1);
			padding: var(--supergutter) var(--supergutter) calc(var(--supergutter) * 3);
			position: relative;
			overflow: hidden;
			&__bg {
				position: absolute;
				bottom: 0;
				right: 5vw;
				display: block;
				width: 460px;
				transform: translateY(33.6%);
				z-index: 0;
				svg {
					display: block;
					@include size(100%,100%);
					fill: rgba($color-platinum, 0.5);
				}
			}
		}
	}
	&__box, &__ctas {
		position: relative;
		z-index: 2;
	}
	&__box {
		display: block;
		width: 100%;
		padding: var(--supergutter);
		&__title, &__text {
			display: block;
			width: 100%;
			max-width: 500px;
			text-align: center;
			margin: 0 auto;
		}
		&__title {
			@extend %text-20;
			font-family: $font-oswald;
			font-variation-settings: 'wght' 500;
			color: $color-onyx;
			text-transform: uppercase;
			margin-bottom: var(--minigutter);
		}
		&__text {
			@extend %text-18;
			font-weight: 400;
		}
	}
	&__ctas {
		@extend %flexCenterCenter;
		width: 100%;
		margin: 0 0 var(--supergutter);
	}
	&__btn {
		@extend %flexCenterCenter;
		@include size(max-content,var(--smallbtn));
		background: $color-denim;
		padding: 0 var(--gutter);
		color: $color-white;
		margin: 0 var(--thirdgutter);
		border-radius: var(--radius);
	}
}
</style>