<template>
	<div class="customselect" :tabindex="tabindex" @blur="open = false" :class="direction">
		<div class="customselect__selected" :class="{ open: open }" @click="open = !open">
			<span v-if="selected && selected.name" class="customselect__selected__value" v-html="selected.name"></span>
			<span class="customselect__selected__icon">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--back" width="100%" height="100%"></use>
				</svg>
			</span>
		</div>
		<div class="customselect__items" :class="{ selectHide: !open }">
			<div v-for="(option, i) of options" :key="i" @click="selectValue(option)" class="customselect__option">
				<template v-if="option && option.name">
					<span v-html="option.name"></span>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'CustomSelect',
	props: {
		options: {
			type: Array,
			required: true,
		},
		default: {
			type: String,
			required: false,
			default: null
		},
		tabindex: {
			type: Number,
			required: false,
			default: 0
		},
		direction: {
			type: String,
			required: false,
			default: 'down'
		},
		filter: {
			type: String,
			required: true
		},
		firstdisabled: {
			type: Boolean,
			required: false,
			default: false,
		}
	},
	data() {
		return {
			selected: this.default.name ? this.default.name : this.options.length > 0 ? this.options[0] : null,
			open: false,
		}
	},
	methods: {
		selectValue(option) {
			this.selected = option;
			this.open = false;
			EventBus.$emit('selected-value', { opts: option.id, filter: this.filter })
		}
	},
	mounted() {
		// EventBus.$emit('selected-value', { opts: this.selected.id, filter: this.filter });

		EventBus.$on('reinit-select', () => {
			
			if(this.firstdisabled) {
				let all = this.$el.querySelectorAll('.customselect__option');
				all[0].classList.add('disabled');
			}
			this.$forceUpdate();
		})
	}
}
</script>
<style lang="scss">
.customselect {
	position: relative;
	@include size(auto, var(--smallbtn));
	min-width: 85px;
	text-align: left;
	outline: none;
	line-height: var(--smallbtn);
	&__selected {
		@extend %flexCenterCenter;
		background: $color-white;
		@include size(100%,100%);
		box-shadow: -3px 5px 5px rgba($color-onyx, 0.1);
		border-radius: var(--radius);
		padding: 0 var(--minigutter);
		cursor: pointer;
		user-select: none;
		&__value {
			display: block;
			width: max-content;
			@extend %text-14;
			font-weight: 500;
			margin-right: 10px;
		}
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--minigutter),var(--minigutter));
			margin-left: 10px;
			transition: 180ms linear;
			svg {
				display: block;
				@include size(75%,75%);
				fill: $color-black;
				transform: rotate(-90deg);
			}
		}
		&.open {
			box-shadow: none;
			.customselect__selected__icon {
				transform: rotate(180deg);
			}
		}
	}
	&__items {
		position: absolute;
		left: 0;
		background: $color-white;
		display: block;
		width: 100%;
		max-height: 350px;
		border-radius: var(--radius);
		text-align: center;
		transition: 180ms linear;
		box-shadow: 0 0 6px rgba($color-onyx, 0.2);
		overflow: auto;
		&.selectHide {
			display: none;
		}
	}
	&__option {
		display: block;
		color: $color-black;
		cursor: pointer;
		user-select: none;
		&.disabled {
			pointer-events: none !important;
		}
	}
	&.up {
		.customselect__items {
			bottom: 0;
		}
	}
	&.down {
		.customselect__items {
			top: 0;
		}
	}
}
</style>
