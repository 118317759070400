import { email } from "vuelidate/lib/validators";

export default {
	install: function(Vue) {
		let service = {};
		Vue.mixin({
			mounted() {
				/**
				 * Crea i parametri per la lista
				 * @param page
				 * @param sort
				 * @param search
				 * @param search_fields
				 * @returns {string}
				 */
				const buildQueryParams = (params) => {
					let paramData = [];
					if(params) {
						Object.keys(params).map(key => {
							paramData.push(`${key}=${encodeURIComponent(params[key])}`);
						})
						return (paramData.length) ? params.join('&') : ``;
					}
					return ``;
				}

				/**
				 * QR
				 */
				let qr = {};
				qr.list = (plant_id) => {
					return this.$api.get(`/qr/list/${plant_id}`);
				}
				qr.code = (code) => {
					return this.$api.get(`/qr/${code}`);
				}
				qr.docs = (code, pointID) => {
					return this.$api.get(`/qr/${code}/${pointID}/documents`);
				}
				qr.parts = (code, pointID) => {
					return this.$api.get(`/qr/${code}/${pointID}/spareparts`);
				}
				service.qr = qr;

				/**
				 * CART
				 */
				let cart = {};
				cart.checkout = (cart) => {
					return this.$api.post('/cart', cart);
				}

				service.cart = cart;


				/**
				 *
				 * ADMIN
				 *
				 */


				/**
				 * LOGIN
				 */

				let login = (info) => {
					return this.$api.post('/user/login', info);
				}
				service.login = login;


				/**
				 * DASHBOARD
				 */
				let dashboard = {};

				dashboard.status = () => {
					return this.$api.get('/dashboard/status');
				}
				dashboard.customers = () => {
					return this.$api.get(`/dashboard/customers`);
				}

				dashboard.plants = {};
				dashboard.plants.get = (customer) => {
					let c = customer ? `?customer=${customer}` : '';
					return this.$api.get(`/dashboard/plants${c}`);
				}
				dashboard.plants.detail = (id) => {
					return this.$api.get(`/dashboard/plants/${id}`);
				}
				dashboard.plants.sections = (id) => {
					return this.$api.get(`/dashboard/plants/${id}/sections`);
				}

				dashboard.orders = (params) => {
					let p = [];
					if(params) {
						if(params.page) {
							p.push(`page=${params.page}`);
						}
						if(params.item_per_page) {
							p.push(`item_per_page=${params.item_per_page}`);
						}
						if(params.status) {
							p.push(`status=${params.status}`);
						}
						if(params.customer) {
							p.push(`customer=${params.customer}`);
						}
						if(params.plant) {
							p.push(`plant=${params.plant}`);
						}
						if(params.sort) {
							p.push(`sort=${params.sort}`);
						}
					}
					return this.$api.get(`/dashboard/orders${params ? `?${p.join('&')}` : ''}`)
				}

				dashboard.checkout = (cart) => {
					return this.$api.post('/dashboard/orders', cart);
				}

				dashboard.orderDetail = (id) => {
					return this.$api.get(`/dashboard/orders/${id}`);
				}

				dashboard.cancelOrder = (id) => {
					return this.$api.delete(`/dashboard/orders/${id}`);
				}

				service.dashboard = dashboard;

			}
		});
		Vue.prototype.$service = service;
	}
}
