<template>
	<div class="ordersxpage">
		<span class="ordersxpage__label">
			{{ $t('admin.dashboard.perpage') }}
		</span>
		<CustomSelect :options="options" default="10" :tabindex="tabindex" direction="up" filter="item_per_page" />
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

import CustomSelect from '@/views/admin/components/common/CustomSelect';

export default {
	name: 'OrdersPerPage',
	components: {
		CustomSelect
	},
	data() {
		return {
			options: [
				{
					id: '10',
					name: '10'
				},
				{
					id: '20',
					name: '20'
				},
				{
					id: '50',
					name: '50'
				},
				{
					id: '100',
					name: '100'
				},
			],
			tabindex: 1,
		}
	}
}
</script>
<style lang="scss">
.ordersxpage {
	position: absolute;
	top: 50%;
	right: var(--gutter);
	@extend %flexCenterEnd;
	height: 100%;
	transform: translateY(-50%);
	&__label {
		display: block;
		width: max-content;
		@extend %text-14;
		font-weight: 500;
		padding-right: var(--minigutter);
	}
}
</style>