import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		status: [],
		orders: {
			total: 0,
		},
		customers: [],
		plants: []
	},
	mutations: {},
	actions: {},
	modules: {},
});
