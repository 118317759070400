<template>
	<span class="loader"></span>
</template>
<script>
export default {
	name: "Loader",
};
</script>
<style lang="scss">
.loader {
	display: block;
	@include size(130px,4px);
	background: rgba($color-onyx, 0.2);
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		@include size(100%,100%);
		background: $color-red;
		animation: l16 1s infinite;
	}
}
@keyframes l16 {
	0% {
		width: 0;
		left: 0;
	}
	66% {
		width: 100%;
		left: 0;
	}
	100% {
		width: 0;
		left: 100%;
	}
}
</style>
