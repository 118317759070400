<template>
	<span class="prodpreview">
		<span class="prodpreview__col image">
			<figure class="prodpreview__media">
				<template v-if="p.pictureUrl">
					<img class="prodpreview__media__image" :src="p.pictureUrl" alt="" />
				</template>
				<template v-else>
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--logo-e" width="100%" height="100%"></use>
					</svg>
				</template>
			</figure>
		</span>
		<span class="prodpreview__col prod" v-html="p.description"></span>
		<span class="prodpreview__col code" v-html="p.id"></span>
		<span class="prodpreview__col price">
			<template v-if="p.prices && p.prices.length && p.prices[0].price">
				&euro; {{ p.prices[0].price }}
			</template>
		</span>
		<span class="prodpreview__col qta">
			<span v-if="isLoading" class="qta__loading">
				<span class="qta__loading__label"></span>
				<span class="qta__loading__spinner">
					<Loader />
				</span>
			</span>
			<span class="qta__box" :class="{ 'disabled': isLoading }">
				<span class="qta__cta remove" @click="decreaseQuantity">-</span>
				<input type="number" name="number" min="0" class="qta__input" v-model="p.qta" />
				<span class="qta__cta add" @click="increaseQuantity">+</span>
			</span>
		</span>
	</span>
</template>
<script>
import { EventBus } from '@/events_bus.js';

import Loader from '@/views/admin/components/common/Loader';

export default {
	name: 'ProductPreview',
	props: {
		p: {
			type: Object,
			required: true
		},
		selection: {
			type: Object,
			required: true,
		}
	},
	components: {
		Loader
	},
	data() {
		return {
			howmany: 0,
			isLoading: false,
			cart: null,
			qta: 0,
		}
	},
	methods: {
		decreaseQuantity() {
			this.isLoading = true;
			if(this.p.qta > 1) {
				this.p.qta -= 1;
				EventBus.$emit('update-quantity', { what: 'remove', id: this.p.id, qta: this.p.qta, price: this.p.price, name: this.p.description });
				this.isLoading = false;
			} else {
				this.isLoading = true;
				this.p.qta = 0;
				EventBus.$emit('remove-from-cart', this.p.id);
				this.isLoading = false;
			}
		},
		increaseQuantity() {
			this.isLoading = true;
			this.p.qta += 1;
			EventBus.$emit('update-quantity', { what: 'add', id: this.p.id, qta: this.p.qta, price: this.p.price, name: this.p.description });
			this.isLoading = false;
		},
		init() {
			let temp = localStorage.getItem('')
			if(this.p && this.p.prices && this.p.prices.length) {
				this.p.price = this.p.prices[0].price;
			}
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.prodpreview {
	@extend %flexCenterStart;
	width: 100%;
	border-bottom: 1px dotted rgba($color-onyx, 0.2);
	&__col {
		@extend %flexCenterStart;
		padding: var(--thirdgutter);
		&.image { width: 10%; }
		&.prod { width: 25%; }
		&.code { width: 25%; }
		&.price { width: 15%; }
		&.qta { width: 30%; }
	}
	&__media {
		@extend %flexCenterCenter;
		background: rgba($color-onyx,0.1);
		@include size(var(--supergutter),var(--supergutter));
		border-radius: 100%;
		overflow: hidden;
		position: relative;
		&__image {
			display: block;
			@include size(100%,100%);
			fill: cover;
			position: relative;
			z-index: 1;
		}
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			@include size(40%,40%);
			fill: rgba($color-onyx, 0.2);
			transform: translate(-50%,-50%);
			z-index: 0;
		}
	}
	&__prod, &__code, &__price {
		font-variation-settings: 'wght' 500;
	}
	.qta {
		@extend %flexCenterEnd;
		&__loading {
			@extend %flexCenterStart;
			width: 50%;
			@include size(50%,var(--input));
			&__spinner {
				display: block;
				width: calc(80px / cos(45deg));
				height: 14px;
			}
		}
		&__box {
			@extend %flexCenterEnd;
			width: 50%;
			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
		}
		&__cta,
		&__input {
			@extend %flexCenterCenter;
			@include size(var(--icon),var(--smallbtn));
			border-radius: var(--radius);
			text-align: center;
		}
		&__cta {
			@extend %text-30;
			background: $color-denim;
			color: $color-white;
			font-variation-settings: 'wght' 700;
			cursor: pointer;
		}
		&__input {
			border: 1px solid rgba($color-onyx,0.3);
			margin: 0 var(--thirdgutter);
			-moz-appearance: textfield;
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
	}
}
</style>
