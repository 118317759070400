<template>
	<div class="home">
		<span class="home__logo">
			<svg viewBox="0 0 280.6 50">
				<use xlink:href="#logo--main" width="100%" height="100%"></use>
			</svg>
		</span>
		<router-link :to="{ name: 'app.page' }" class="home__btn">App</router-link>
		<router-link :to="{ name: 'login.page' }" class="home__btn">Admin</router-link>
	</div>
</template>

<script>
export default {
	name: 'Home',
	methods: {
		isMobile() {
			if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true
			} else {
				return false
			}
 		}
	},	
	mounted() {
		if(this.isMobile()) {
			this.$router.push({ name: 'app.page' })
		} else {
			this.$router.push({ name: 'login.page' })
		}
	}
}
</script>
<style lang="scss">
.home {
	@extend %flexCenterCenter;
	flex-direction: column;
	background: $color-red;
	@include size(100%, 100vh);
	overflow: hidden;
	position: relative;
	&__logo {
		position: absolute;
		top: var(--gutter);
		left: 50%;
		@extend %flexCenterCenter;
		@include size(280px, 50px);
		transform: translateX(-50%) scale(0.7);
		svg {
			display: block;
			@include size(100%, 100%);
			fill: $color-white;
		}
	}
	&__btn {
		@extend %flexCenterCenter;
		@include size(250px, var(--input));
		background: $color-white;
		@extend %text-20;
		font-family: $font-oswald;
		text-align: center;
		color: $color-red;
		margin: var(--minigutter) 0;
		border-radius: var(--radius);
		text-transform: uppercase;
	}
}
</style>
