<template>
	<main id="recap" class="page recap">
		<div v-if="loading" class="loading">
			<span class="loading__box">
				<span class="loading__logo">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--logo-e" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="loading__text">
					Loading...
				</span>
			</span>
		</div>
		<div class="recap__box">
			<span class="recap__box__title" v-html="$t('app.common.recap.yourorder')"></span>
			<div v-if="cart" class="recap__box__list">
				<span v-for="c in cart" :key="c.code" class="recap__box__item ritem">
					<span class="ritem__info">
						<span class="ritem__info__name">
							{{ c.name }}
						</span>
						<small class="ritem__info__code">
							cod: {{ c.code }}
						</small>
					</span>
					<span class="ritem__data">
						<span v-if="c.price && c.price.value && c.price.value != 0" class="ritem__data__price">
							&euro; {{ c.price.value }}
						</span>
						<small class="ritem__data__quantity">
							x {{ c.quantity }}
						</small>
					</span>
				</span>
			</div>
			<div class="recap__box__totals">
				<span class="recap__box__totals__label" v-html="$t('app.common.totals')"></span>
				<span class="recap__box__totals__value">
					&euro; {{ total }}
				</span>
			</div>
		</div>
		<div class="recap__ctas">
			<span class="recap__ctas__btn btn green" @click="confirmOrder">
				{{ $t('app.ctas.confirmorder') }}
			</span>
			<span class="recap__ctas__btn btn simple" @click="editOrder">
				{{ $t('app.ctas.editorder') }}
			</span>
		</div>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';

import Loading from '@/views/app/components/common/Loading';

export default {
	name: 'Recap',
	data() {
		return {
			cart: null,
			total: 0,
			loading: false,
		}
	},
	components: {
		Loading
	},
	methods: {
		getTotals() {
			this.total = 0;
			if(this.cart && this.cart.length) {
				this.cart.forEach(c => {
					this.total += (c.quantity * c.price.price);
				})
			}
		},
		init() {
			this.cart = JSON.parse(localStorage.getItem('cart'));
			if(this.cart && this.cart.length) {
				this.getTotals();
			}
			EventBus.$emit('loaded');
		},
		editOrder() {
			return this.$router.go(-1);
		},
		confirmOrder() {
			this.loading = true;
			let finalCart = [];
			this.cart.forEach(c => {
				finalCart.push({ id: c.id, qta: c.quantity, code: c.qr });
			})


			this.$service.cart.checkout(finalCart).then(() => {
				console.log('FINITO');
				EventBus.$emit('confirm-order');
				this.cart = [];
				// localStorage.setItem('cart', JSON.stringify([]));
				console.log('RIMUOVI CARRELLO');
				localStorage.removeItem('cart');
				console.log('CARRELLO RIMOSSO');
				this.loading = false;
			}).catch(err => {
				console.error('ERR', err);
				if(err && err.error && err.error.code == "ERR_NETWORK") {
					alert('Nessuna connessione ad internet, riprovare più tardi.')
					this.loading = false;
				}
			})

			// EMPTY CART
		}
	},
	mounted() {
		this.init();
	}
};
</script>
<style lang="scss">
.recap {
	display: block;
	background: $color-white;
	width: 100%;
	min-height: 100vh;
	padding-top: var(--head);
	.loading {
		position: fixed;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: rgba($color-white, 0.9);
		@include size(100%,100vh);
		z-index: 100000;
		&__box {
			display: block;
			width: auto;
			text-align: center;
			margin: 0 auto;
		}
		&__logo {
			display: block;
			width: 100px;
			margin-bottom: var(--gutter);
			animation: rotatePlane 2.2s infinite linear;
			svg {
				display: block;
				@include size(100%,100%);
				fill: $color-red;
			}
		}
		@keyframes rotatePlane {
			0% {
				transform: rotateY(0deg);
			}
			100% {
				transform: rotateY(-359.9deg);
			}
		}
		&__text {
			color: $color-red;
		}
	}
	&__box {
		display: block;
		background: $color-seasalt;
		width: calc(100% - var(--gutter));
		padding: var(--gutter);
		margin: var(--minigutter);
		border-radius: calc(var(--radius) * 2);
		&__title {
			@extend %text-24;
			display: block;
			width: 100%;
			font-family: $font-oswald;
			font-variation-settings: 'wght' 500;
			color: $color-black;
			text-align: center;
			text-transform: uppercase;
			margin-bottom: var(--gutter);
		}
		&__list {
			display: block;
			width: 100%;
		}
		&__item {
			@extend %flexCenterBetween;
			width: 100%;
			padding: var(--minigutter) 0;
			border-bottom: 1px dotted rgba($color-black, 0.4);
			&:last-child {
				border-bottom: 0;
			}
			.ritem {
				&__info,
				&__data {
					flex-direction: column;
					flex-wrap: wrap;
				}
				&__info {
					@extend %flexStartCenter;
					width: 70%;
					&__name {
						@extend %text-16;
						font-weight: 500;
					}
					&__code {
						@extend %text-13;
					}
				}
				&__data {
					@extend %flexEndCenter;
					width: 30%;
					text-align: right;
					&__price {
						@extend %text-18;
						font-weight: 500;
					}
					&__quantity {
						@extend %text-13;
					}
				}
			}
		}
		&__totals {
			@extend %flexCenterBetween;
			width: 100%;
			margin-top: var(--gutter);
			@extend %text-24;
			font-family: $font-oswald;
			font-variation-settings: 'wght' 500;
			border-top: 1px solid rgba($color-black, 0.4);
			padding-top: var(--gutter);
			&__label {
				text-align: left;
			}
			&__value {
				text-align: right;
			}
		}
	}
	&__ctas {
		display: block;
		width: 100%;
		margin: var(--minigutter) 0;
		&__btn {
			margin: 0 auto;
		}
	}
}
</style>