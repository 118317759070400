<template>
	<aside class="sidebar">
		<div class="sidebar__head">
			<span v-if="user" class="logo">
				{{ user.company }}
			</span>
		</div>
		<div class="sidebar__content">
			<div class="sidebar__content__box">
				<nav class="sidebar__menu menu">
					<small class="menu__label">
						{{ $t('menu.label') }}
					</small>
					<div class="menu__list">
						<router-link :to="{ name: 'dashboard.page' }" class="menu__item">
							<span class="menu__item__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--doc"></use>
								</svg>
							</span>
							<span class="menu__item__label">
								{{ $t('admin.menu.richieste') }}<sup v-if="totals">{{ totals }}</sup>
							</span>
						</router-link>
						<!-- <router-link to="" class="menu__item">
							<span class="menu__item__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--docs"></use>
								</svg>
							</span>
							<span class="menu__item__label">
								{{ $t('admin.menu.storico') }}<sup>25</sup>
							</span>
						</router-link> -->
						<router-link :to="{ name: 'neworder.page' }" class="menu__item">
							<span class="menu__item__icon">
								<svg viewBox="0 0 50 50">
									<use xlink:href="#icon--doc-new"></use>
								</svg>
							</span>
							<span class="menu__item__label">
								{{ $t('admin.menu.crea') }}
							</span>
						</router-link>
					</div>
				</nav>
				
			</div>
		</div>
	</aside>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'Sidebar',
	data() {
		return {
			totals: 0,
			user: null,
		}
	},
	watch: {
		user: {
			immediate: true,
			handler(newval, oldval) {
				return newval
			}
		},
		totals: {
			immediate: true,
			handler(newval, oldval) {
				return newval;
			}
		}
	},
	methods: {
		init() {
			let user = JSON.parse(localStorage.getItem('user'));
			if(user) {
				this.user = user;
			}
			let totalOrders = localStorage.getItem('total-orders');
			if(totalOrders) {
				this.totals = totalOrders;
			}
		}	
	},
	mounted() {
		this.init();
		EventBus.$on('orderslist-updated', () => {
			this.init();
		})
	}
}
</script>
<style lang="scss">
.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	background: $color-seasalt;
	@include size(var(--sidebar),100vh);
	overflow: hidden;
	&__head, &__content {
		width: 100%;
	}
	&__head {
		@extend %flexCenterStart;
		@include size(100%,var(--head));
		padding: 0 var(--gutter);
		.logo {
			@extend %text-13;
			font-family: $font-oswald;
			font-variation-settings: 'wght' 700;
		}
	}
	&__content {
		display: block;
		height: calc(100% - var(--head));
		overflow: auto;
		position: relative;
		&__box {
			position: absolute;
			top: 0;
			left: 0;
			@extend %flexStartBetween;
			flex-direction: column;
			width: 100%;
			min-height: 100%;
			padding: var(--gutter) var(--minigutter);
		}
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		background: $color-timberwolf;
		@include size(1px,100%);
	}
	&__lastlogo {
		display: block;
		margin: 0 auto;
	}
	&__menu {
		display: block;
		width: 100%;
		.menu {
			&__label {
				display: block;
				@extend %text-13;
				color: $color-black;
				text-transform: uppercase;
				font-family: $font-oswald;
				font-variation-settings: 'wght' 500;
			}
			&__list {
				display: block;
				width: 100%;
				margin: var(--gutter) 9;
			}
			&__item {
				@extend %flexCenterStart;
				width: 100%;
				margin: var(--minigutter) 0;
				transition: 180ms linear;
				& * {
					transition: 180ms linear;
				}
				&__icon {
					@extend %flexCenterStart;
					@include size(var(--icon),var(--icon));
					svg {
						display: block;
						@include size(60%,60%);
						fill: $color-black;
					}
				}
				&__label {
					@extend %flexBaselineStart;
					width: calc(100% - var(--icon));
					sup {
						display: block;
						padding-left: calc(var(--minigutter) / 2);
					}
				}
				&:hover,
				&.router-link-exact-active {
					.menu__item {
						&__icon svg {
							fill: $color-red;
						}
						&__label {
							color: $color-red;
						}
					}
				}
			}
		}
	}
}
</style>