<template>
	<span v-if="prod" class="product">
		<span class="product__dragger">
			<span class="product__icon">
				<template v-if="prod.image != ''">
					<img class="product__icon__image" :src="prod.image" alt="" />
				</template>
				<template v-else>
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--logo-e" width="100%" height="100%"></use>
					</svg>
				</template>
			</span>
			<span class="product__label">
				<span v-if="prod.name" class="product__label__title">
					{{ prod.name }}
				</span>
				<small v-if="prod.code" class="product__label__code">
					cod: {{ prod.code }}
				</small>
			</span>
			<span class="product__info">
				<span class="product__info__price" v-html="`${prod.price.currency ? $options.filters.currency(prod.price.currency) : '&euro;' } ${prod.price.price}`"></span>
				<small class="product__info__quantity">{{ prod.quantity }} {{ (prod.quantity > 1) ? $t('app.common.pieces') : $t('app.common.piece') }}</small>
			</span>
			<span class="product__cta">
				<span class="product__cta__edit" @click="editProduct">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--edit" width="100%" height="100%"></use>
					</svg>
				</span>
			</span>
		</span>
		<span class="product__delete" @click="deleteProduct">
			{{ $t('app.ctas.delete') }}
		</span>
	</span>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'Product',
	props: {
		prod: {
			type: Object,
			requied: true,
			default: {}
		}
	},
	data() {
		return {

		}
	},
	methods: {
		editProduct() {
			EventBus.$emit('edit-product-quantity', this.prod);
		},
		deleteProduct() {
			EventBus.$emit('confirm-delete', this.prod);
			this.$el.style.height = '0px';
		},
		revertDelete(handle) {
			this.$el.style = '';
			handle.style.transform = `translateX(0px)`;
		},
		dragItem(handle) {
			let initpos = 0;
			let currentPos = 0;
			handle.addEventListener("touchstart", (event) => {
				initpos = event.touches[0].clientX;
			});
			handle.addEventListener("touchmove", (event) => {
				currentPos = initpos - event.touches[0].clientX;
				if(currentPos < 120 && currentPos >= 0) {
					handle.style.transform = `translateX(${(currentPos) * -1}px)`;
				}
				if(currentPos < 0) {
					handle.style.transform = `translateX(0px)`;
				}
			});
			handle.addEventListener('touchend', (event) => {
				if(currentPos < 120 && currentPos >= 0) {
					handle.style.transform = `translateX(0px)`;
				}
			})
		},
	},
	mounted() {
		let dragger = this.$el.querySelector('.product__dragger');
		this.dragItem(dragger);
		EventBus.$on('update-quantity', prod => {
			if(this.prod.id == prod.id) {
				this.prod.quantity = prod.quantity;
			}
		})

		EventBus.$on('revert-delete', () => {
			this.revertDelete(dragger);
		})
	}
}
</script>
<style lang="scss">
.product {
	display: block;
	width: 100%;
	border-top: 1px dotted rgba($color-black, 0.4);
	color: $color-black;
	position: relative;
	transition: 150ms linear;
	overflow: hidden;
	&__dragger {
		@extend %flexCenterStart;
		background: $color-white;
		@include size(100%,100%);
		position: relative;
		padding: calc(var(--gutter) / 3) var(--minigutter);
		z-index: 1;
		transition: 250ms linear;
	}
	&__delete {
		position: absolute;
		top: 0;
		right: 0;
		@extend %flexCenterCenter;
		background: $color-chili;
		color: $color-white;
		@include size(var(--delete),100%);
		z-index: 0;
	}
	&__icon {
		@extend %flexCenterCenter;
		background: $color-denim;
		@include size(var(--list),var(--list));
		border-radius: 100%;
		overflow: hidden;
		&__image {
			display: block;
			@include size(100%,100%);
			object-fit: cover;
		}
		svg {
			display: block;
			@include size(50%,50%);
			fill: $color-white;
		}
	}
	&__label {
		@extend %flexStartCenter;
		flex-direction: column;
		width: calc(100% - var(--list) - var(--delete));
		padding-left: var(--minigutter);
		&__title {
			font-weight: 600;
		}
		&__cod {
			@extend %text-13;
		}
	}
	&__info {
		@extend %flexEndCenter;
		flex-direction: column;
		width: calc(var(--delete) - var(--medicon));
		&__price {
			@extend %text-18;
			font-weight: 500;
		}
		&__quantity {
			@extend %text-13;
			color: $color-grey;
		}
	}
	&__cta {
		@extend %flexEndCenter;
		flex-direction: column;
		@include size(40px,100%);
		&__edit {
			@extend %flexCenterCenter;
			@include size(21px,21px);
			svg {
				display: block;
				@include size(100%,100%);
				fill: $color-onyx;
			}
		}
	}
}
</style>