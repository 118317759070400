<template>
	<main id="cart" class="page cart">
		<span class="cart__title">{{ $t('app.common.cart') }}</span>
		<template v-if="!nocart">
			<div class="cart__content">
				<div class="cart__content__list">
					<Product v-for="(c, cI) in cart" :key="`id_${c.id}_${cI}`" :prod="c" />
				</div>
				<div class="cart__content__totals">
					<span class="cart__content__totals__label">
						{{ $t('app.common.totals') }}
					</span>
					<span class="cart__content__totals__values totals">
						<span class="totals__price">
							&euro; <span class="totals__price__value" v-model="total">{{ total }}</span>
						</span>
						<small class="totals__price__label">
							{{ $t('app.common.novat') }}
						</small>
					</span>
				</div>
				<div class="cart__content__cta">
					<span class="cart__content__cta__btn btn red" @click="emptyCart">
						{{ $t('app.ctas.emptycart') }}
					</span>
				</div>
			</div>
			<div class="cart__next">
				<router-link :to="{ name: 'recap.page' }" class="cart__next__btn btn green">
					{{ $t('app.ctas.checkout') }}
				</router-link>
			</div>
		</template>
		<template v-else>
			<div class="cart__noprods">
				{{ $t('app.common.noprods') }}
			</div>
		</template>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
const _ = require('lodash');

import Product from '@/views/app/components/common/Product';

export default {
	name: 'Cart',
	components: {
		Product
	},
	data() {
		return {
			nocart: false,
			cart: null,
			total: 0,
			deleted: null,
		}
	},
	methods: {
		init() {
			this.nocart = false;
			this.cart = [];
			let localCart = JSON.parse(localStorage.getItem('cart'));
			if(localCart && localCart.length) {
				localCart.forEach(lc => {
					this.cart.push(lc);
				})

				this.getTotals();
			} else {
				this.nocart = true;
			}
			EventBus.$emit('loaded');
		},
		getTotals() {
			this.total = 0;
			if(this.cart && this.cart.length) {
				this.cart.forEach(c => {
					this.total += (c.quantity * c.price.price);
				})
			}
		},
		removeFromCart() {
			let toberemoved = this.cart.filter(c => {
				return c.id == this.deleted.id
			})
			if(toberemoved && toberemoved.length) {
				let itemIndex = _.findIndex(this.cart, (item) => item.id == this.deleted.id);
				this.cart.splice(itemIndex, 1);
				localStorage.setItem('cart', JSON.stringify(this.cart));
				setTimeout(() => {
					EventBus.$emit('cart-update');
					this.getTotals();
				}, 10)
			}
		},
		emptyCart() {
			EventBus.$emit('remove-from-cart');
		}
	},
	mounted() {
		this.init();
		EventBus.$on('confirm-delete', item => {
			this.deleted = item;
		})
		EventBus.$on('clear-deleted', () => {
			this.removeFromCart();
			// this.deleted = null;
		})

		EventBus.$on('update-quantity', (newnumber) => {
			localStorage.setItem('cart', JSON.stringify(this.cart));
			this.getTotals();
		})

		EventBus.$on('cart-is-empty', () => {
			EventBus.$emit('cart-update');
			this.nocart = true;
			this.cart = [];
		})
	}
};
</script>
<style lang="scss">
.cart {
	display: block;
	background: $color-white;
	width: 100%;
	min-height: calc(100vh - var(--head));
	overflow-y: auto;
	// padding-top: var(--head);
	&__title {
		@extend %flexCenterStart;
		background: $color-red;
		@include size(90%, var(--ribbon));
		clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%);
		@extend %text-24;
		font-family: $font-oswald;
		color: $color-white;
		padding: 0 var(--gutter);
		text-transform: uppercase;
	}
	&__noprods {
		display: block;
		width: 100%;
		padding: var(--supergutter) 0;
		text-align: center;
	}
	&__next {
		position: fixed;
		bottom: 0;
		left: 0;
		display: block;
		background: $color-white;
		width: 100%;
		padding: calc(var(--minigutter) * 1.5) 0;
		border-top: 1px solid $color-dim;
		&__btn {
			margin: 0 auto;
			padding: 0 var(--gutter);
		}
	}
	&__content {
		display: block;
		width: 100%;
		padding-bottom: calc(var(--supergutter) * 2);
		&__list,
		&__totals,
		&__cta {
			width: 100%;
		}
		&__list,
		&__cta {
			display: block
		}
		&__totals {
			@extend %flexBaselineBetween;
			background: $color-seasalt;
			padding: var(--gutter) var(--minigutter);
			&__label, &__values {
				width: 50%;
			}
			&__label {
				@extend %text-30;
				font-weight: 500;
			}
			&__values {
				@extend %flexEndCenter;
				flex-direction: column;
				text-align: right;
				position: relative;
				.totals {
					&__price {
						@extend %text-30;
						font-weight: 500;
						&__label {
							position: absolute;
							top: 100%;
							right: 0;
							font-style: italic;
						}
					}
				}
			}
		}
		&__cta {
			padding: var(--gutter);
			&__btn {
				height: calc(var(--btn) * 0.85);
				margin: 0 auto;
			}
		}
	}
}
</style>
