<template>
	<div v-if="p" class="modaladmin">
		<div class="modaladmin__box">
			<div class="modaladmin__head">
				<span class="modaladmin__head__redlabel" v-html="$t('admin.cart.modal.editqta.title')"></span>
				<span class="modaladmin__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<div class="modaladmin__content">
				<span class="editqta">
					<span v-if="isLoading" class="editqta__loading">
						<span class="editqta__loading__label"></span>
						<span class="editqta__loading__spinner">
							<Loader />
						</span>
					</span>
					<span class="editqta__box" :class="{ 'disabled': isLoading }">
						<span class="editqta__cta remove" @click="decreaseQuantity">-</span>
						<input type="number" name="number" min="0" class="editqta__input" v-model="p.qta" />
						<span class="editqta__cta add" @click="increaseQuantity">+</span>
					</span>
				</span>
				<small class="editqta__text" v-html="$t('admin.cart.modal.editqta.text')"></small>
			</div>
			<template v-if="isLoading">
				<div class="modaladmin__loader">
					<Loader />
				</div>
			</template>
			<template v-else>
			
			</template>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';

import Loader from '@/views/admin/components/common/Loader.vue';

export default {
	name: 'ModalEditQuantity',
	components: {
		Loader
	},
	data() {
		return {
			p: null,
			howmany: 0,
			isLoading: false,
			cart: null,
			qta: 0,
		}
	},
	methods: {
		decreaseQuantity() {
			this.isLoading = true;
			if(this.p.qta > 1) {
				this.p.qta -= 1;
				EventBus.$emit('update-quantity', { what: 'remove', id: this.p.id, qta: this.p.qta, price: this.p.price, name: this.p.description });
				this.isLoading = false;
			} else {
				this.isLoading = true;
				this.p.qta = 0;
				EventBus.$emit('remove-from-cart', this.p.id);
				this.isLoading = false;
			}
		},
		increaseQuantity() {
			this.isLoading = true;
			this.p.qta += 1;
			EventBus.$emit('update-quantity', { what: 'add', id: this.p.id, qta: this.p.qta, price: this.p.price, name: this.p.description });
			this.isLoading = false;
		},
		init() {
			console.log('INIT EDIT')
			if(this.p) {
				gsap.timeline()
					.to(this.$el, {
						duration: 0.3,
						opacity: 1,
						pointerEvents: 'all'
					})
					.to(this.$el.querySelector('.modaladmin__box'), {
						duration: 0.6,
						y: 0,
						opacity: 1,
						pointerEvents: 'all'
					})
			}
		},
		closeModal() {
			EventBus.$emit('update-cart-view');
			gsap.timeline({
				onComplete: () => {
					gsap.set([this.$el, this.$el.querySelector('.modaladmin__box')], {
						clearProps: 'all'
					})
				}
			})
				.to(this.$el.querySelector('.modaladmin__box'), {
					duration: 0.6,
					y: 100,
					opacity: 0,
					pointerEvents: 'none'
				})
				.to(this.$el, {
					duration: 0.3,
					opacity: 0,
					pointerEvents: 'none'
				})
		},
		confirmDelete() {
			this.isLoading = true;
			this.$service.dashboard.cancelOrder(this.orderId).then(res => {
				this.isLoading = false;
				this.closeModal()
				this.$router.push({ name: 'dashboard.page'})
			});
		}
	},
	mounted() {
		EventBus.$on('edit-quantity', (prod) => {
			this.p = prod;
			setTimeout(() => {
				this.init();
			}, 100);
		});
	}
}
</script>
<style lang="scss">
@import '~@/assets/scss/components/modals';
.modaladmin {
	&__recap {
		display: block;
		width: 100%;
		padding: 0 var(--supergutter);
		transition: 180ms linear;
		&__title {
			display: block;
			width: 100%;
			@extend %text-24;
			font-family: $font-oswald;
			color: $color-black;
			text-transform: uppercase;
			border-bottom: 1px dotted $color-onyx;
			padding-bottom: var(--thirdgutter);
			margin-bottom: var(--thirdgutter);
		}
		&__content {
			display: block;
			width: 100%;
			small, span {
				display: block;
				width: 100%;
			}
			span {
				margin-bottom: var(--thirdgutter);
			}
		}
		&__totals {
			@extend %flexCenterBetween;
			@extend %text-22;
			font-family: $font-oswald;
			color: $color-black;
			border-top: 1px dotted $color-onyx;
			padding-top: var(--thirdgutter);
			margin-top: var(--thirdgutter);
			margin-bottom: var(--gutter);
		}
	}
	&__box {
		max-width: 450px;
	}
	&__loader {
		@extend %flexCenterCenter;
		flex-direction: column;
		width: 100%;
		padding: var(--supergutter);
	}
	.editqta {
		@extend %flexCenterCenter;
		&__loading {
			@extend %flexCenterStart;
			width: 50%;
			@include size(50%,var(--input));
			&__spinner {
				display: block;
				width: calc(80px / cos(45deg));
				height: 14px;
			}
		}
		&__box {
			@extend %flexCenterEnd;
			width: 50%;
			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
		}
		&__cta,
		&__input {
			@extend %flexCenterCenter;
			@include size(var(--supergutter),var(--supergutter));
			border-radius: var(--radius);
			text-align: center;
		}
		&__cta {
			@extend %text-30;
			background: $color-denim;
			color: $color-white;
			font-variation-settings: 'wght' 700;
			cursor: pointer;
		}
		&__input {
			border: 1px solid rgba($color-onyx,0.3);
			margin: 0 var(--thirdgutter);
			-moz-appearance: textfield;
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
		&__text{
			display: block;
			width: 100%;
			text-align: center;
			margin-top: var(--gutter);
			padding: 0 var(--supergutter)
		}
	}
}
</style>
