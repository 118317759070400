<template>
	<main id="login" class="page-admin login">
		<div class="content">
			<h2 class="login__title">
				{{ $t('admin.login.title') }}
			</h2>
			<form id="login-form" class="form loginform" @submit.prevent="onSubmit">
				<div class="loginform__field">
					<label for="email" class="form__label loginform__label" v-html="$t('admin.login.email')"></label>
					<input type="text" name="email" id="email" class="form__input loginform__input" v-model="form.email" />
				</div>
				<div class="loginform__field">
					<label for="password" class="form__label loginform__label" v-html="$t('admin.login.password')"></label>
					<input type="password" name="password" id="password" class="form__input loginform__input" v-model="form.password" />
				</div>
				<span v-if="error" class="loginform__error">
					{{ error }}
				</span>
				<div class="loginform__field loginform__ctas">
					<template v-if="isLoading">
						<Loader />
					</template>
					<template v-else>
						<button type="submit" class="loginform__btn" v-html="$t('admin.login.accedi')"></button>
					</template>
				</div>
			</form>
			<div class="login__info">
				<strong class="login__info__title" v-html="$t('admin.login.psw.title')"></strong>
				<span class="login__info__text" v-html="$t('admin.login.psw.text')"></span>
			</div>
		</div>
		<span class="login__bg">
			<svg viewBox="0 0 50 50">
				<use xlink:href="#icon--logo-e" width="100%" height="100%"></use>
			</svg>
		</span>
	</main>
</template>
<script>
import Loader from "@/views/admin/components/common/Loader";

export default {
	name: 'Login',
	components: {
		Loader
	},
	data() {
		return {
			isLoading: false,
			error: null,
			form: {
				email: null,
				password: null,
			}
		}
	},
	methods: {
		async onSubmit() {
			this.login();
		},
		login() {
			this.isLoading = true;
			this.$service.login(this.form).then(res => {
				this.error = null;
				if(res && res.token) {
					localStorage.setItem('user', JSON.stringify(res));
					localStorage.setItem('token', res.token)
					window.location.href = '/#/admin/dashboard'
					this.isLoading = false;
				}
			}).catch(err => {
				console.error('ERR', err);
				switch(err.status) {
					case 401:
						this.error = 'Email o password errate.'
						break;
				}
			})
		}
	},
	mounted() {
	}
}
</script>
<style lang="scss">
#login {
	background: $color-white;
	.content {
		text-align: center;
		position: relative;
		z-index: 2;
		padding-top: var(--supergutter);
	}
	.login {
		&__bg {
			position: fixed;
			bottom: 0;
			right: 15vw;
			display: block;
			@include size(33.6vw,33.6vw);
			transform: translateY(33.6%);
			svg {
				display: block;
				@include size(100%,100%);
				fill: $color-platinum;
			}
		}
		&__title {
			display: block;
			width: 100%;
			margin: 0 auto;
			@extend %text-24;
			font-family: $font-oswald;
			color: $color-red;
			text-transform: uppercase;
		}
		&__info {
			display: block;
			width: 300px;
			text-align: center;
			margin: var(--supergutter) auto 0;
			&__title,
			&__text {
				display: block;
				width: 100%;
				text-align: center;
			}
			&__title {
				@extend %text-18;
				font-variation-settings: 'wght' 500;
				font-family: $font-oswald;
				margin-bottom: var(--minigutter);
				text-transform: uppercase;
			}
		}
	}
	.loginform {
		display: block;
		width: 100%;
		max-width: 300px;
		margin: var(--gutter) auto 0;
		&__error {
			display: block;
			width: 100%;
			text-align: center;
			@extend %text-14;
			color: $color-error;
			text-transform: uppercase;
			margin-bottom: var(--thirdgutter);
		}
		&__field {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
		}
		&__label {
			display: block;
			width: 100%;
			text-align: left;
		}
		&__input {
			@include size(100%,var(--smallinput));
			border: 1px solid rgba($color-onyx, 0.3) !important;
		}
		&__btn {
			@extend %flexCenterCenter;
			background: $color-red;
			@include size(max-content, var(--smallbtn));
			color: $color-white;
			border-radius: var(--radius);
			margin: 0 auto;
			text-transform: uppercase;
			@extend %text-15;
			font-variation-settings: 'wght' 600;
			padding: 12px var(--gutter);
			cursor: pointer;
		}
		&__ctas {
			.loader {
				margin: 0 auto;
			}
		}
	}
}
</style>
