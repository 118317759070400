<template>
	<div id="app">
		<Icons />
		<router-view/>
	</div>
</template>
<script>
import Icons from '@/views/layouts/utils/Icons';

export default {
	name: 'App',
	components: {
		Icons
	},
	data() {
		return {
			wWidth: 0,
		}
	},
	methods: {
		isMobile() {
			if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true
			} else {
				return false
			}
 		},
		// onResize() {
		// 	let mode = localStorage.getItem('mode');
		// 	this.windowWidth = window.innerWidth;
		// 	if(this.windowWidth < 1200) {
		// 		localStorage.setItem('mode', 'mobile');
		// 		if(mode != 'mobile') {
		// 			window.location.reload();
		// 		}
		// 	} else {
		// 		localStorage.setItem('mode', 'desktop');
		// 		if(mode != 'desktop') {
		// 			window.location.reload();
		// 		}
		// 	}
		// }
	},
	mounted() {
		// this.onResize();
		// this.$nextTick(() => {
		// 	window.addEventListener('resize', () => {
		// 		this.onResize();
		// 	});
		// });

		if(this.isMobile()) {
			
			this.$router.push({ name: this.$route.name, params: this.$route.params })
		} else {
			if(!localStorage.getItem('token')) {
				this.$router.push({ name: 'login.page' })
			}
		}
	}
}
</script>
<style lang="scss">
#app {
	display: block;
	width: 100%;
	height: auto;
	min-height: 100vh;
}
</style>
