<template>
	<div v-if="product" class="snackbar" :class="{ 'show': show }">
		<span class="snackbar__label" v-html="`${product.name} ${$t('app.common.snackbar')}`"></span>
		<span class="snackbar__btn btn white" @click="revertDelete">
			{{ $t('app.ctas.cancel') }}
		</span>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'SnackbarDelete',
	data() {
		return {
			show: false,
			product: null,
			revert: false,
		}
	},
	methods: {
		revertDelete() {
			EventBus.$emit('revert-delete');
			clearTimeout(this.revert);
			this.show = false;
		}
	},
	mounted() {
		EventBus.$on('confirm-delete', prod => {
			this.show = true;
			this.product = prod;
			this.revert = setTimeout(() => {
				EventBus.$emit('clear-deleted');
				this.show = false;
			}, 3000)
		})
	}
}
</script>
<style lang="scss">
.snackbar {
	position: fixed;
	bottom: var(--gutter);
	right: var(--minigutter);
	@extend %flexCenterBetween;
	background: $color-onyx;
	@include size(calc(100% - var(--gutter)), var(--supergutter));
	padding: 0 var(--minigutter);
	transform: translateY(150%);
	transition: 250ms linear;
	pointer-events: none;
	z-index: 1000;
	&__label {
		color: $color-white;
	}
	&__btn {
		height: calc(var(--btn) * 0.8);
	}
	&.show {
		transform: translateY(0%);
		pointer-events: all;
	}
}
</style>