<template>
	<router-link :to="{ name: 'order-detail.page', params: { id: o.id} }" v-if="o" class="orderpreview">
		<span class="orderpreview__col number">
			<template v-if="o.ticket_id && o.ticket_id.id">
				<span v-html="o.ticket_id.id"></span>
			</template>
		</span>
		<span class="orderpreview__col client">
			<template v-if="o.user && o.user.company">
				<span v-html="o.user.company"></span>
			</template>
		</span>
		<span class="orderpreview__col impianto">
			<template v-if="o.plant && o.plant.name">
				<span v-html="o.plant.name"></span>
			</template>
		</span>
		<span class="orderpreview__col date">
			<template v-if="o.formatted_date">
				<span v-html="o.formatted_date"></span>
			</template>
		</span>
		<span class="orderpreview__col status">
			<span v-if="o.status && o.statusLabel" class="status__bullet" :class="o.status" v-html="o.statusLabel"></span>
		</span>
	</router-link>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';

export default {
	name: 'OrderPreview',
	props: {
		o: {
			type: Object,
			required: true,
		}
	}
}
</script>
<style lang="scss">
.orderpreview {
	// display: grid;
	// grid-template-columns: repeat(12, 8.33%);
	@extend %flexCenterStart;
	@include size(100%,var(--smallinput));
	background: $color-white;
	box-shadow: -5px 5px 6px rgba($color-dim, 0.1);
	border-radius: var(--radius);
	margin-bottom: var(--minigutter);
	color: $color-black;
	&__col {
		@extend %flexCenterStart;
		height: 100%;
		span {
			@extend %text-15;
			font-weight: 500;
		}
		&.number {
			width: 15%;
			padding-left: var(--minigutter);
		}
		&.client { width: 30%; }
		&.impianto { width: 30%; }
		&.date { width: 15%; }
		&.status {
			width: 10%;
			justify-content: flex-end;
			padding-right: var(--minigutter);
			.status__bullet {
				@extend %flexCenterCenter;
				height: 25px;
				border-radius: 12px;
				color: $color-white;
				@extend %text-13;
				padding: 0 var(--minigutter);
				&.cancel { background: $color-cancel; }
				&.done { background: $color-done; }
				&.draft { background: $color-draft; }
				&.open { background: $color-open; }
				&.pending { background: $color-pending; }
			}
		}
	}
}
</style>