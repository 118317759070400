<template>
	<div class="orders">
		<div class="orders__head">
			<span class="orders__head__col number">
				<span class="orders__head__col__label" v-html="$t('admin.orders.numbers')"></span>
				<span class="orders__head__col__sort" data-dir="-" data-value="ticket_id.id" @click="sortResults($event)">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
			</span>
			<span class="orders__head__col client">
				<span class="orders__head__col__label" v-html="$t('admin.orders.client')"></span>
				<span class="orders__head__col__sort" data-dir="-" data-value="user.company" @click="sortResults($event)">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
			</span>
			<span class="orders__head__col impianto">
				<span class="orders__head__col__label" v-html="$t('admin.orders.plant')"></span>
				<span class="orders__head__col__sort" data-dir="-" data-value="plant.name" @click="sortResults($event)">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
			</span>
			<span class="orders__head__col date">
				<span class="orders__head__col__label" v-html="$t('admin.orders.date')"></span>
				<span class="orders__head__col__sort invert" data-dir="+" data-value="date" @click="sortResults($event)">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--back" width="100%" height="100%"></use>
					</svg>
				</span>
			</span>
			<span class="orders__head__col status">
				<span class="orders__head__col__label" v-html="$t('admin.orders.status')"></span>
			</span>
		</div>
		<div class="orders__body">
			<LoadingOrders v-if="isLoading" />
			<template v-if="!isLoading && orders && orders.length">
				<OrderPreview v-for="o in orders" :key="o.id" :o="o" />
			</template>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';

import LoadingOrders from '@/views/admin/components/common/LoadingOrders';
import OrderPreview from '@/views/admin/components/orders/OrderPreview';

export default {
	name: 'OrdersTable',
	components: {
		LoadingOrders,
		OrderPreview
	},
	data() {
		return {
			orders: null,
			isLoading: false,
			filters: {
				page: 1,
				item_per_page: 10,
				status: null,
				customer: null,
				plant: null,
			},
			statuses: null,
		}
	},
	methods: {
		handleData(res) {
			this.isLoading = false;
			this.orders = res.data;
			this.orders.forEach(o => {
				o.formatted_date = dayjs(o.date).format('DD/MM/YY');
				if(this.statuses) {
					o.statusLabel = this.statuses[o.status];
				}
			})
			
			EventBus.$emit('orders-ready', res);
		},
		updateResults(params) {
			this.isLoading = true;
			this.$service.dashboard.orders(params).then(res => {
				this.handleData(res);
			})
		},
		sortResults(e) {
			this.isLoading = true;
			let dir = e.target.getAttribute('data-dir');
			let val = e.target.getAttribute('data-value');
			if(dir == '+') {
				e.target.setAttribute('data-dir', '-');
			} else {
				e.target.setAttribute('data-dir', '+');
			}

			if(e.target.classList.contains('invert')) {
				e.target.classList.remove('invert');
			} else {
				e.target.classList.add('invert');
			}

			this.$service.dashboard.orders({ sort: `${dir}${val}` }).then(res => {
				if(res && res.data) {
					this.handleData(res);
					localStorage.setItem('order-sorting', `${dir}${val}`);
				}
			}).catch(err => {
				console.error('ERR', err);
			})

		},
		init() {
			this.isLoading = true;
			this.orders = [];
			this.$service.dashboard.orders({ sort: `-date` }).then(res => {
				if(res && res.total) {
					localStorage.setItem('total-orders', res.total)
				}
				this.$service.dashboard.status().then(stat => {
					this.statuses = stat;
				}).then(() => {
					if(res && res.data) {
						this.handleData(res);
					}
				})
			}).catch(err => {
				console.error('ERR', err);
			})
		}
	},
	mounted() {
		this.init();
		EventBus.$on('pagination', params => {
			this.filters.page = params;
			let sort = localStorage.getItem('order-sorting');
			if(sort) {
				this.filters.sort = sort;
			}
			this.updateResults(this.filters);
		})
		EventBus.$on('selected-value', params => {
			this.filters.page = 1;
			this.filters[params.filter] = params.opts
			this.updateResults(this.filters);
		})
	}
}
</script>
<style lang="scss">
.orders {
	display: block;
	width: 100%;
	padding: var(--gutter) 0;
	&__head {
		// display: grid;
		// grid-template-columns: repeat(12, 8.33%);
		position: sticky;
		top: 0;
		@extend %flexCenterStart;
		@include size(100%,var(--smallinput));
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			background: $color-seasalt;
			@include size(calc(100% + 10px), 100%);
			pointer-events: none;
			z-index: 0;
			transform: translateX(-5px);
		}
		&__col {
			@extend %flexCenterStart;
			height: var(--smallinput);
			position: relative;
			z-index: 2;
			&__label {
				display: block;
				width: max-content;
				@extend %text-16;
				font-weight: 600;
			}
			&__sort {
				@extend %flexCenterCenter;
				@include size(var(--smallicon),var(--smallicon));
				cursor: pointer;
				svg {
					display: block;
					@include size(50%,50%);
					fill: $color-black;
					transform: rotate(-90deg);
					pointer-events: none;
				}
				&.invert {
					transform: rotate(180deg);
				}
			}
			&.number { width: 15%; }
			&.client { width: 30%; }
			&.impianto { width: 30%; }
			&.date { width: 15%; }
			&.status {
				width: 10%;
				text-align: right;
				justify-content: flex-end;
				padding-right: calc(var(--gutter) * 0.75);
			}
		}
	}
	&__body {
		display: block;
		width: 100%;
		padding: var(--minigutter) 0;
	}
}
</style>
