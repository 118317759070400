<template>
	<div class="modal" :class="{'show': show}">
		<div class="modal__box">
			<span class="modal__box__close" @click="closeModal">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--close" width="100%" height="100%"></use>
				</svg>
			</span>
			<span class="modal__box__icon">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--danger" width="100%" height="100%"></use>
				</svg>
			</span>
			<span class="modal__box__text" v-html="$t('app.common.empty.proceed')"></span>
			<div class="modal__box__ctas">
				<span class="modal__box__ctas__btn btn grey" @click="closeModal">
					{{ $t('app.ctas.cancel') }}
				</span>
				<span class="modal__box__ctas__btn btn red" @click="confirmDelete">
					{{ $t('app.ctas.delete') }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'ModalRemoveFromCart',
	data() {
		return {
			show: false,
			prod: null,
			deleted: null,
		}
	},
	methods: {
		closeModal() {
			this.show = false;
		},
		confirmDelete() {
			let emptycart = []
			localStorage.setItem('cart', JSON.stringify(emptycart));
			console.log('DELETE ALL');
			setTimeout(() => {
				EventBus.$emit('cart-is-empty');
				this.show = false;
			}, 10)
		},
	},
	mounted() {
		EventBus.$on('remove-from-cart', () => {
			this.show = true;
		})
	}
}
</script>
<style lang="scss">
.modal {
	position: fixed;
	top: 0;
	left: 0;
	@extend %flexCenterCenter;
	background: rgba($color-black, 0.8);
	@include size(100%,100vh);
	opacity: 0;
	pointer-events: none;
	z-index: 1000;
	&__box {
		display: block;
		background: $color-white;
		width: 90%;
		max-height: 90vh;
		padding: var(--gutter);
		border-radius: var(--radius);
		position: relative;
		overflow-y: auto;
		transform: translateY(100px);
		opacity: 0;
		&__close {
			position: absolute;
			top: var(--minigutter);
			right: var(--minigutter);
			@extend %flexCenterCenter;
			background: rgba($color-timberwolf, 0.5);
			@include size(var(--smallicon),var(--smallicon));
			border-radius: 100%;
			svg {
				display: block;
				@include size(50%,50%);
			}
		}
		&__icon {
			@extend %flexCenterCenter;
			@include size(100%,var(--icon));
			margin-top: var(--gutter);
			svg {
				display: block;
				@include size(var(--icon),var(--icon));
				fill: $color-black;
			}
		}
		&__text {
			@extend %text-18;
			display: block;
			width: 100%;
			font-weight: 500;
			text-align: center;
			margin: calc(var(--minigutter) * 1.5) 0 var(--supergutter);
		}
		&__ctas {
			@extend %flexCenterCenter;
			width: 100%;
			margin-top: var(--minigutter);
			&__btn {
				margin: 0 10px;
				height: calc(var(--btn) * 0.75);
			}
		}
	}
	&.show {
		opacity: 1;
		pointer-events: all;
		.modal__box {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
</style>