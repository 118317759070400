<template>
    <div id="app-layout" class="app-layout" :class="{ 'fullscreen': isScan }">
		<Loading />
		<Head />
        <router-view />


		<!--CART -->
		<ModalRemoveFromCart />
		<ModalEditProduct />
		<SnackbarDelete />

		<!-- RECAP -->
		<ModalConfirmOrder />

		<!-- ERRORS -->
		<ModalAPIErrors />
		<ModalCodeError />
    </div>
</template>

<script>
import { EventBus } from '@/events_bus.js';
import Head from '@/views/app/components/layout/Head';

import Loading from '@/views/app/components/common/Loading';

import ModalConfirmOrder from '@/views/app/components/modals/ModalConfirmOrder';
import ModalRemoveFromCart from '@/views/app/components/modals/ModalRemoveFromCart';
import ModalEditProduct from '@/views/app/components/modals/ModalEditProduct';
import SnackbarDelete from '@/views/app/components/modals/SnackbarDelete';

import ModalAPIErrors from '@/views/app/components/modals/errors/ModalAPIErrors';
import ModalCodeError from '@/views/app/components/modals/errors/ModalCodeError';

export default {
    name: 'AppLayout',
	components: {
		Head,
		Loading,
		ModalConfirmOrder,
		ModalRemoveFromCart,
		ModalEditProduct,
		SnackbarDelete,
		ModalAPIErrors,
		ModalCodeError
	},
	data() {
		return {
			isScan: true,
		}
	},
	watch: {
		'$route': {
			immediate: true,
			handler(newval, oldval) {
				if(this.$router.currentRoute.name != 'app.page') {
					this.isScan = false;
				} else {
					this.isScan = true;
				}
			}
		},
	},
	mounted() {
		if(!localStorage.getItem('cart')){
			localStorage.setItem('cart', JSON.stringify([]));
		}
		if(this.$router.currentRoute.name == 'app.page') {
			this.isScan = true;
		} else {
			this.isScan = false;
		}
		
	}
}
</script>

<style lang="scss">
.app-layout {
	display: block;
	background: $color-white;
	width: 100%;
	height: calc(100vh);
	padding-top: var(--head);
	overflow: hidden;
	&.fullscreen {
		height: 100vh;
		padding-top: 0;
	}
}
</style>
