import Vue from "vue";
import VueRouter from "vue-router";

// Layouts
import AdminLayout from '@/views/layouts/AdminLayout';
import AppLayout from '@/views/layouts/AppLayout';
import LoginLayout from '@/views/layouts/LoginLayout';

// Base
import Home from "@/views/Home.vue";

// App
import QRScan from '@/views/app/QRScan';
import Plant from '@/views/app/Plant';
import Cart from '@/views/app/Cart';
import Recap from '@/views/app/Recap';
import List from '@/views/app/List';

// Admin
import Login from '@/views/admin/Login';
import Dashboard from '@/views/admin/Dashboard';
import OrderDetail from '@/views/admin/OrderDetail';
import NewOrder from '@/views/admin/NewOrder';
import CartAdmin from '@/views/admin/CartAdmin';
import OrderConfirmed from '@/views/admin/OrderConfirmed';

Vue.use(VueRouter);
const guardAuthService = async (to, from, next) => {
	try {
		let token = localStorage.getItem('token');
		if(token && token !== '') {
			next()
		} else {
			next({
				name: 'login.page',
			})
		}
	} catch (e) {
		console.log('CATCH');
		next({
			name: 'login.page',
		})
	}
};

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/admin",
		component: AdminLayout,
		beforeEnter: (to, from, next) => {
			guardAuthService(to, from, next);
		},
		children: [
			{
				path: 'dashboard',
				name: 'dashboard.page',
				component: Dashboard
			},
			{
				path: 'order/:id',
				name: 'order-detail.page',
				component: OrderDetail
			},
			{
				path: 'neworder',
				name: 'neworder.page',
				component: NewOrder
			},
			{
				path: 'carrello',
				name: 'carrello.page',
				component: CartAdmin
			},
			{
				path: 'order-confirmed',
				name: 'order-confirmed.page',
				component: OrderConfirmed
			}
		],
	},
	{
		path: '/login',
		component: LoginLayout,
		children: [
			{
				path: '',
				name: 'login.page',
				component: Login
			}
		]
	},
	{
		path: '/app',
		component: AppLayout,
		children: [
			{
				path: 'scan',
				name: 'app.page',
				component: QRScan
			},
			{
				path: 'plant/list/:customer/:code',
				name: 'list.page',
				component: List
			},
			{
				path: 'plant/:code',
				name: 'plant.page',
				component: Plant
			},
			{
				path: 'cart',
				name: 'cart.page',
				component: Cart
			},
			{
				path: 'recap',
				name: 'recap.page',
				component: Recap
			}
		]
	}
];

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
});

export default router;
