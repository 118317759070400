<template>
	<div v-if="pages" class="orderspagination">
		<span v-for="index in pages" :key="index" class="orderspagination__page" :class="{ 'current': (index == current)}" @click="goToPage(index)">
			{{ index }}
		</span>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'OrdersPagination',
	data() {
		return {
			current: null,
			pages: null
		}
	},
	methods: {
		goToPage(page) {
			EventBus.$emit('pagination', page);
		},
		init(info) {
			this.current = info.page;
			this.pages = info.pages;
		}
	},
	mounted() {
		EventBus.$on('orders-ready', (info) => {
			this.init(info);
		})
		EventBus.$on('orders-updated', (info) => {
			this.init(info);
		})
	}
}
</script>
<style lang="scss">
.orderspagination {
	position: absolute;
	top: 50%;
	left: 50%;
	@extend %flexCenterCenter;
	@include size(max-content, 100%);
	transform: translate(-50%,-50%);
	&__page {
		@extend %flexCenterCenter;
		@include size(35px,35px);
		@extend %text-14;
		font-weight: 500;
		border-radius: var(--radius);
		transition: 180ms linear;
		cursor: pointer;
		&.current {
			color: $color-red;
			pointer-events: none;
		}
		&:hover {
			background: $color-white;
			box-shadow: 0 0 6px rgba($color-onyx, 0.2);
		}
	}
}
</style>