<template>
	<main id="neworder" class="page-admin neworder">
		<div class="neworder__head">
			<span class="neworder__head__title">
				<span class="neworder__head__title__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--docs" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="neworder__head__title__label">
					{{ $t('admin.neworder.title') }}
				</span>
			</span>
		</div>
		<div class="neworder__content">
			<div class="neworder__filters">
				<div class="neworder__filters__col neworder__filter">
					<small class="neworder__filter__label" v-html="$t('admin.neworder.filters.customers')"></small>
					<CustomSelect v-if="customers && customers.length" :options="customers" :default="customers[0].name" :tabindex="0" filter="customers" class="neworder__filter__select" />
				</div>
				<div class="neworder__filters__col neworder__filter">
					<small class="neworder__filter__label" v-html="$t('admin.neworder.filters.plants')"></small>
					<CustomSelect v-if="plants && plants.length" :options="plants" :default="plants[0].name" :tabindex="1" class="neworder__filter__select" filter="plants" :firstdisabled="true" />
				</div>
				<div class="neworder__filters__col neworder__filter">
					<small class="neworder__filter__label" v-html="$t('admin.neworder.filters.section')"></small>
					<CustomSelect v-if="sections && sections.length" :options="sections" :default="'0'" :tabindex="1" class="neworder__filter__select" filter="sections" />
				</div>
			</div>
			<div class="neworder__results">
				<template v-if="nofilters">
					<span class="noresults">
						<span class="noresults__title" v-html="$t('admin.neworder.noresults.title')"></span>
						<span class="noresults__text" v-html="$t('admin.neworder.noresults.text')"></span>
					</span>
				</template>
				<template v-else>
					<div class="neworder__list">
						<template v-if="prods && prods.length">
							<span class="neworder__list__search">
								<input type="text" id="search" placeholder="Cerca..." v-model="searchTerm"/>
							</span>
							<div class="neworder__list__prods prodslist">
								<template v-if="searchEmpty">
									<span class="prodslist__empty">
										<span class="prodslist__empty__title" v-html="$t('admin.neworder.search.noresults.title')"></span>
										<span class="prodslist__empty__text" v-html="$t('admin.neworder.search.noresults.text')"></span>
									</span>
								</template>
								<template v-else>
									<div class="prodslist__head">
										<span class="prodslist__head__col image" v-html="$t('admin.neworder.head.image')"></span>
										<span class="prodslist__head__col prod" v-html="$t('admin.neworder.head.prod')"></span>
										<span class="prodslist__head__col code" v-html="$t('admin.neworder.head.code')"></span>
										<span class="prodslist__head__col price" v-html="$t('admin.neworder.head.price')"></span>
										<span class="prodslist__head__col qta"></span>
									</div>
									<div class="prodslist__list">
										<template v-if="isLoading">
											Loading...
										</template>
										<template v-else>
											<ProductPreview v-for="p in prods" :key="p.id" :p="p" :selection="selection" />
										</template>
									</div>
								</template>
							</div>
						</template>
						<template v-else>
							<span class="neworder__list__empty">
								<span class="neworder__list__empty__title" v-html="$t('admin.neworder.search.noprods.title')"></span>
								<span class="neworder__list__empty__text" v-html="$t('admin.neworder.search.noprods.text')"></span>
							</span>
						</template>
					</div>
				</template>
			</div>
		</div>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
const _ = require('lodash');

import CustomSelect from '@/views/admin/components/common/CustomSelect';
import ProductPreview from '@/views/admin/components/products/ProductPreview';

export default {
	name: 'NewOrder',
	components: {
		CustomSelect,
		ProductPreview
	},
	data() {
		return {
			nofilters: true,
			customers: [],
			plants: [],
			sections: [],
			prods: [],
			searchTerm: null,
			selection: {},
			cart: [],
			deleted: null,
			isLoading: false,
			prodList: [],
			searchEmpty: true,
		}
	},
	watch: {
		customers: {
			immediate: true,
			deep: true,
			handler(newval, oldval) {
				return newval;
			}
		},
		searchTerm: {
			immediate: false,
			handler(newval, oldval) {
				this.search();
			}
		}
	},
	methods: {
		init() {
			this.$service.dashboard.customers().then(res => {
				if(res && res.length) {
					// if(res.length > 1) {
					// 	this.customers = [
					// 		{
					// 			id: 0,
					// 			name: this.$t('admin.dashboard.filters.allcustomers')
					// 		}
					// 	];
					// }
					this.customers = [
						{
							id: 0,
							name: this.$t('admin.dashboard.filters.allcustomers')
						}
					];
					res.forEach(r => {
						this.customers.push({
							id: r.id,
							name: r.company
						})
					})
				}
			})

			this.plants.push({
				id: 0,
				name: this.$t('admin.neworder.filters.noplants')
			})
			this.sections.push({
				id: 0,
				name: this.$t('admin.neworder.filters.nosections')
			})
		},
		updateQuantity(item) {
			let t = this.cart.filter(c => {
				return c.id == item.id;
			})
			if(t && t.length) {
				t[0].qta = item.qta;
			} else {
				this.cart.push({
					...this.selection,
					id: item.id,
					qta: item.qta,
					price: item.price,
					name: item.name
				});
			}

			// SAVE CART
			localStorage.setItem('cart', JSON.stringify(this.cart));

			EventBus.$emit('update-cart', this.cart);
		},
		removeProduct(id) {
			let itemIndex = _.findIndex(this.cart, (item) => item.id == id);
			this.cart.splice(itemIndex, 1);

			localStorage.setItem('cart', JSON.stringify(this.cart));

			EventBus.$emit('update-cart', this.cart);
		},
		search() {
			if(this.searchTerm && this.searchTerm.length > 2) {
				this.isLoading = true;
				let results = this.prods.filter(p => {
					return p.description.toLowerCase().includes(this.searchTerm.toLowerCase()) || p.id.toLowerCase().includes(this.searchTerm.toLowerCase());
				})
				if(results && results.length) {
					this.prods = [];
					this.isLoading = false;
					this.searchEmpty = false;
					results.forEach(r => {
						this.prods.push(r)
					})
				} else {
					this.isLoading = false;
					this.searchEmpty = true;
				}
			} else {
				this.isLoading = false;
				this.searchEmpty = false;
				this.prods = this.prodList;
			}
		},
		handleSections(params) {
			this.prods = [];
			let item = this.sections.filter(s => {
				return s.id == params.opts;
			})
			if(item && item[0]) {
				if(!item[0].hasOwnProperty('parent')) {
					this.selection.part = item[0].id
					this.selection.partName = item[0].name;
					this.selection.section = null;
				} else {
					this.selection.part = item[0].parent.id
					this.selection.partName = item[0].parent.name;
					this.selection.section = item[0].id
					this.selection.sectionName = item[0].name;
				}

				if(item[0].spareParts && item[0].spareParts.length) {
					this.searchEmpty = false;
					item[0].spareParts.forEach(sp => {
						sp.qta = 0;
						this.prods.push(sp);
					})

					let lc = JSON.parse(localStorage.getItem('cart'));
					if(this.prods && this.prods.length && this.selection) {
						if(lc) {
							this.prods.forEach(p => {
								let temp = lc.filter(c => {
									return p.id == c.id
								});
								if(temp && temp[0]) {
									p.qta = temp[0].qta;
								} else {
									p.qta = 0;
								}
							})
						}
					}
					this.prodList = this.prods;
				}
			}
		},
		initCart(cart) {
			this.cart = cart;
			EventBus.$emit('update-cart', this.cart);

			

		}
	},
	beforeRouteLeave (to, from, next) {
		this.selection = null;
		next();
	},
	mounted() {
		this.init();

		EventBus.$on('selected-value', params => {
			let tempCart = JSON.parse(localStorage.getItem('cart'));
			switch(params.filter) {
				case 'customers':
					if(tempCart && tempCart.length) {
						EventBus.$emit('alert-cart');
					} else {
						this.selection = {}
						this.selection.customer = params.opts;
						
						this.$service.dashboard.customers().then(cust => {
							localStorage.setItem('current-customer', JSON.stringify(cust));
						})
						this.$service.dashboard.plants.get(params.opts).then(res => {
							this.plants = [];
							if(res && res.length) {
								this.plants = [
									{
										id: 0,
										name: this.$t('admin.dashboard.filters.selectplant')
									}
								];
								res.forEach(r => {
									this.plants.push(r);
								})
								EventBus.$emit('reinit-select');
							} else {
								this.plants.push({
									id: 0,
									name: this.$t('admin.dashboard.filters.noplants')
								})
							}
						})
					}
					break;
				case 'plants':
					if(tempCart && tempCart.length) {
						EventBus.$emit('alert-cart')
					} else {
						this.sections = [];
						setTimeout(() => {
							this.$service.dashboard.plants.detail(params.opts).then(ppp => {
								localStorage.setItem('current-plant', JSON.stringify(ppp));
							})
		
							this.$service.dashboard.plants.sections(params.opts).then(res => {
								if(res && res.length) {
									this.selection.plant = params.opts;
									this.selection.part = null
									this.selection.section = null;
		
									res.forEach(r => {
										r.name = `- ${r.name}`;
										this.sections.push(r);
										if(r.sections && r.sections.length) {
											r.sections.forEach(s => {
												s.name = `- - ${s.name}`;
												s.parent = r;
												this.sections.push(s);
											})
										}
									})
								} else {
									this.plants.push({
										id: 0,
										name: this.$t('admin.dashboard.filters.nosections')
									})
								}
							})
						}, 150)
					}
					break;
				case 'sections':
					this.nofilters = false;
					this.handleSections(params);
					break;
			}
			
		})
		
		EventBus.$on('update-quantity', item => {
			this.updateQuantity(item);
		})

		EventBus.$on('remove-from-cart', id => {
			this.removeProduct(id)
		})


		let localCart = JSON.parse(localStorage.getItem('cart'));
		if(localCart && localCart.length) {
			this.initCart(localCart);
		}
	}
}
</script>
<style lang="scss">
.neworder {
	height: 100vh;
	overflow: hidden;
	position: relative;
	&__head, &__content, &__utils {
		padding-left: var(--gutter);
		padding-right: var(--gutter);
	}
	&__head {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterBetween;
		@include size(100%, calc(var(--supergutter) * 1.5));
		padding-top: var(--gutter);
		// padding-bottom: var(--minigutter);
		z-index: 10;
		&__title {
			@extend %flexCenterStart;
			width: max-content;
			&__icon {
				@extend %flexCenterStart;
				@include size(var(--icon),var(--icon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-red;
				}
			}
			&__label {
				display: block;
				width: calc(100% - var(--icon));
				@extend %text-24;
				font-family: $font-oswald;
				font-variation-settings: 'wght' 500;
				text-transform: uppercase;
			}
		}
	}
	&__content {
		display: block;
		width: 100%;
		height: calc(100% - ((var(--supergutter) * 1.5)));
		margin: calc(var(--supergutter) * 1.5) 0 var(--supergutter);
		overflow: auto;
	}
	&__filters {
		@extend %flexStartBetween;
		width: 100%;
		margin: var(--supergutter) 0;
		&__col {
			display: block;
			width: 30%;
		}
		.customselect {
			&__option {
				text-align: left;
				justify-content: flex-start;
				padding: 0 var(--minigutter);
			}
		}
	}
	&__filter {
		&__label {
			display: block;
			width: 100%;
			@extend %text-15;
			margin-bottom: var(--minigutter);
		}
		&__select {
			width: 100%;
			.customselect {
				&__selected {
					justify-content: space-between;
				}
			}
		}
	}
	&__results {
		display: block;
		width: 100%;
		.noresults {
			display: block;
			width: 100%;
			padding-top: var(--supergutter);
			&__title, &__text {
				display: block;
				width: 100%;
				max-width: 300px;
				text-align: center;
				margin: 0 auto;
			}
			&__title {
				@extend %text-20;
				font-family: $font-oswald;
				font-variation-settings: 'wght' 500;
				color: $color-onyx;
				text-transform: uppercase;
				margin-bottom: var(--minigutter);
			}
			&__text {
				@extend %text-18;
				font-weight: 400;
			}
		}
	}
	&__list {
		display: block;
		width: 100%;
		background: $color-white;
		box-shadow: -3px 3px 6px rgba($color-onyx, 0.1);
		border-radius: var(--radius);
		padding: var(--gutter) 0;
		&__search {
			display: block;
			width: calc(100% - var(--supergutter));
			margin: 0 var(--gutter) var(--gutter);
			input {
				@include size(100%,var(--smallbtn));
				border-radius: calc(var(--smallbtn) / 2);
				border: 2px solid $color-dim;
				padding: 0 var(--minigutter);
			}
		}
		&__prods {
			display: block;
			width: 100%;
			.prodslist {
				&__empty {
					display: block;
					width: 100%;
					padding-top: var(--supergutter);
					padding-bottom: calc(var(--supergutter) * 2);
					&__title, &__text {
						display: block;
						width: 100%;
						max-width: 300px;
						text-align: center;
						margin: 0 auto;
					}
					&__title {
						@extend %text-20;
						font-family: $font-oswald;
						font-variation-settings: 'wght' 500;
						color: $color-onyx;
						text-transform: uppercase;
						margin-bottom: var(--minigutter);
					}
					&__text {
						@extend %text-18;
						font-weight: 400;
					}
				}
				&__head {
					@extend %flexCenterStart;
					width: 100%;
					padding: 0 var(--gutter);
					border-bottom: 1px dotted rgba($color-onyx, 0.2);
					margin-bottom: var(--minigutter);
					&__col {
						display: block;
						height: var(--gutter);
						@extend %text-14;
						&.image { width: 10%; }
						&.prod { width: 25%; }
						&.code { width: 25%; }
						&.price { width: 15%; }
						&.qta { width: 30%; }
					}
				}
				&__list {
					display: block;
					width: 100%;
					padding: 0 var(--gutter);
				}
			}
		}
		&__empty {
			display: block;
			width: 100%;
			padding-top: var(--supergutter);
			padding-bottom: calc(var(--supergutter) * 2);
			&__title, &__text {
				display: block;
				width: 100%;
				max-width: 300px;
				text-align: center;
				margin: 0 auto;
			}
			&__title {
				@extend %text-20;
				font-family: $font-oswald;
				font-variation-settings: 'wght' 500;
				color: $color-onyx;
				text-transform: uppercase;
				margin-bottom: var(--minigutter);
			}
			&__text {
				@extend %text-18;
				font-weight: 400;
			}
		}
	}
}
</style>
