<template>
	<div v-if="loading" class="loading">
		<span class="loading__icon">
			<svg viewBox="0 0 50 50">
				<use xlink:href="#icon--logo-e" width="100%" height="100%"></use>
			</svg>
		</span>
		<span class="loading__label"> Loading... </span>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'Loading',
	data() {
		return {
			loading: false,
		}
	},
	mounted() {
		EventBus.$on('loading', (status) => {
			console.log('LOADING STATUS', status);
			this.loading = status;
		})
	}
}
</script>
<style lang="scss">
.loading {
	position: fixed;
	top: 0;
	left: 0;
	@extend %flexCenterCenter;
	background: $color-red;
	flex-direction: column;
	@include size(100%,100vh);
	z-index: 10000;
	&__icon {
		@extend %flexCenterCenter;
		@include size(var(--input),var(--input));
		margin-bottom: var(--gutter);
		animation: rotatePlane 2.2s infinite linear;
		svg {
			display: block;
			@include size(100%,100%);
			fill: $color-white;
		}
	}
	&__label {
		display: block;
		width: 100%;
		@extend %text-18;
		color: $color-white;
		text-align: center;
	}
	@keyframes rotatePlane {
		0% {
			transform: rotateY(0deg);
		}
		100% {
			transform: rotateY(-359.9deg);
		}
	}
}
</style>