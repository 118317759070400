<template>
	<main id="plist" class="page plist">
		<div class="plist__content">
			<div class="loading-data" :class="{ 'show': isLoading }">
				<span class="loading-data__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--logo-e" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="loading-data__label"> Loading... </span>
			</div>
			<div class="plist__box" :class="{ 'show': (!isLoading && !isLoading)}">
				<div v-if="list && list.length" class="plantlist">
					<router-link :to="{ name: 'plant.page', params: { code: l.code } }" v-for="l in list" :key="l.id" class="plantlist__item">
						<figure class="plantlist__item__media o-ratio u-1:1">
							<img class="plantlist__item__media__image" :src="l.image" alt="" />
						</figure>
						<span class="plantlist__item__info">
							<small class="plantlist__item__info__code">
								{{ l.code }}
							</small>
							<span class="plantlist__item__info__name">
								{{ l.name }}
							</span>
						</span>
					</router-link>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import Panel from "@/views/app/components/common/Panel";
import PanelList from "@/views/app/components/common/PanelList";
import PanelProduct from "@/views/app/components/common/PanelProduct";

export default {
	name: "Plant",
	components: {
	
	},
	data() {
		return {
			isLoaded: false,
			isLoading: true,
			current: null,
			list: null,
		};
	},
	methods: {
		init() {
			if(this.$route && this.$route.params && this.$route.params.code) {
				this.list = {};
				this.current = this.$route.params.code;
				this.isLoading = true;
				this.$service.qr.list(this.current).then(res => {
					console.log('RES', res);
					if(res && res.length) {
						this.list = res;
						
						localStorage.setItem('current-list', JSON.stringify(this.current));
					}
				}).catch(err => {
					EventBus.$emit('api-error');
					console.error('ERR', err);
				}).finally(() => {
					this.isLoading = false;
				})
			}
		},
	},
	mounted() {
		this.init();
	}
};
</script>
<style lang="scss">
.plist {
	display: block;
	background: $color-black;
	@include size(100%, calc(100vh - var(--head)));
	overflow: hidden;
	&__title {
		@extend %flexCenterStart;
		background: $color-red;
		@include size(90%, var(--ribbon));
		clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%);
		@extend %text-16;
		font-family: $font-oswald;
		color: $color-white;
		padding: 0 var(--gutter);
		margin-top: var(--gutter);
		position: relative;
		z-index: 2;
	}
	&__content {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		@include size(100%,100%);
		z-index: 0;
		.temp {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			color: #f9c;
			transform: translate(-50%,-50%);
		}
	}
	&__box {
		display: block;
		background: $color-white;
		@include size(auto,100vh);
		overflow-y: hidden;
		overflow-x: auto;
		z-index: 0;
		position: relative;
		opacity: 0;
		pointer-events: none;
		&__media {
			display: block;
			@include size(auto,100%);
			margin: 0;
			pointer-events: none;
			&__image {
				display: block;
				@include size(auto,100%);
			}
		}
		&.show {
			opacity: 1;
			pointer-events: all;
		}
	}
	.point {
		position: absolute;
		display: block;
		.simple {
			@include size(0,0);
			pointer-events: none;
			z-index: 3;
			&__label {
				@extend %text-20;
				position: absolute;
				top: 50%;
				left: 100%;
				display: block;
				background: $color-white;
				width: max-content;
				font-family: $font-oswald;
				font-variation-settings: 'wght' 500;
				color: $color-denim;
				padding: 6px var(--minigutter);
				text-align: center;
				transform: translate(var(--point), -50%);
				pointer-events: all;
			}
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: calc(100% + (var(--point) / 2));
				display: block;
				background: $color-white;
				@include size(calc(var(--point) / 2),4px);
				transform: translateY(-50%);
			}
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				@include size(var(--point),var(--point));
				border: 3px solid $color-white;
				border-radius: 100%;
				pointer-events: all;
				transform: translate(-50%,-50%);
				transition: 180ms linear;
			}
			&.current {
				&:after {
					background: $color-red;
					@include size(calc(var(--point) * 1.3),calc(var(--point) * 1.3));
				}
			}
			&.reversed {
				&:before {
					left: unset;
					right: calc(100% + (var(--point) / 2));
				}
				.simple__label {
					left: calc((var(--point) / 2) * -1 - 10px);
					transform: translate(-100%, -50%);
				}
			}
		}
		.group {
			@extend %flexCenterCenter;
			flex-direction: column;
			flex-wrap: wrap;
			@include size(var(--group),var(--group));
			background: $color-timberwolf;
			font-family: $font-oswald;
			font-variation-settings: 'wght' 500;
			color: $color-denim;
			text-align: center;
			border: 3px solid $color-white;
			border-radius: 100%;
			transform: translate(-50%,-50%);
			z-index: 2;
			&__number,
			&__minilabel {
				text-align: center;
			}
			&__number {
				@extend %text-24;
				line-height: 1;
			}
			&__minilabel {
				@extend %text-11;
				text-transform: uppercase;
			}
		}
	}
	
	.loading-data {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		background: $color-seasalt;
		flex-direction: column;
		@include size(100%,100vh);
		opacity: 0;
		pointer-events: none;
		z-index: 10000;
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--input),var(--input));
			margin-bottom: var(--gutter);
			animation: rotatePlane 2.2s infinite linear;
			svg {
				display: block;
				@include size(100%,100%);
				fill: $color-timberwolf;
			}
		}
		&__label {
			display: block;
			width: 100%;
			@extend %text-18;
			color: $color-timberwolf;
			text-align: center;
		}
		@keyframes rotatePlane {
			0% {
				transform: rotateY(0deg);
			}
			100% {
				transform: rotateY(-359.9deg);
			}
		}
		&.show {
			opacity: 1;
			pointer-events: all;
		}
	}
	
	.plantlist {
		display: block;
		width: 100%;
		padding: var(--gutter) var(--minigutter);
		&__item {
			@extend %flexCenterStart;
			background: $color-seasalt;
			width: 100%;
			padding: var(--thirdgutter);
			margin-bottom: var(--minigutter);
			border-radius: var(--radius);
			&__media {
				display: block;
				@include size(var(--icon),var(--icon));
				border-radius: var(--radius);
				overflow: hidden;
				position: relative;
				&__image {
					@include size(100%,100%);
					object-fit: cover;
				}
			}
			&__info {
				display: block;
				width: calc(100% - var(--icon));
				padding-left: var(--gutter);
				&__code,
				&__name {
					display: block;
					width: 100%;
				}
				&__name {
					font-weight: 600;
				}
			}
		}
	}
}
</style>
