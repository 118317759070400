<template>
	<div class="loadingorders">
		<span class="loadingorders__pholder"></span>
		<span class="loadingorders__pholder"></span>
		<span class="loadingorders__pholder"></span>
		<span class="loadingorders__pholder"></span>
		<span class="loadingorders__pholder"></span>
		<span class="loadingorders__pholder"></span>
		<span class="loadingorders__pholder"></span>
		<span class="loadingorders__pholder"></span>
		<span class="loadingorders__pholder"></span>
		<span class="loadingorders__pholder"></span>
	</div>
</template>
<script>
export default {
	name: 'LoadingOrders',
	data() {
		return {

		}
	},
	methods: {
		init() {

		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.loadingorders {
	display: block;
	@include size(100%,100%);
	&__pholder {
		display: block;
		background: rgba($color-dim, 0.12);
		@include size(100%,var(--smallinput));
		margin-bottom: var(--minigutter);
		position: relative;
		overflow: hidden;
		border-radius: var(--radius);
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			display: block;
			background: linear-gradient(to bottom, rgba($color-white, 0.2), transparent);
			@include size(150px,150px);
			transform: translate(-100%,-50%) rotate(35deg);
			animation: loopmove 1.4s linear infinite;
		}
	}
	@keyframes loopmove {
		0% {
			left: -10%;
		}
		100% {
			left: 120%;
		}
	}
}
</style>