<template>
	<span class="prodrecap">
		<span class="prodrecap__col prod" v-html="p.name"></span>
		<span class="prodrecap__col code" v-html="p.id"></span>
		<span class="prodrecap__col qta">
			{{ p.qta }}
		</span>
		<span class="prodrecap__col price">
			&euro; {{ p.price }}
		</span>
		<span class="prodrecap__col cta">
			<span class="cta__btn edit" @click="editQuantity()">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--edit" width="100%" height="100%"></use>
				</svg>
			</span>
			<span class="cta__btn delete" @click="removeFromCart()">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--delete" width="100%" height="100%"></use>
				</svg>
			</span>
		</span>
	</span>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'ProductRecap',
	props: {
		p: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			isLoading: false,
			cart: null,
			label: ''
		}
	},
	methods: {
		editQuantity() {
			let lc = JSON.parse(localStorage.getItem('cart'));
			
			let itemIndex = _.findIndex(lc, (item) => item.id == this.p.id);
			
			EventBus.$emit('edit-quantity', lc[itemIndex]);
		},
		removeFromCart() {
			EventBus.$emit('confirm-delete-prod', this.p.id);
		},
		init() {
			// if(this.p.qta > 1) {
			// 	this.label = this.$t('admin.cart.pezzi')
			// } else {
			// 	this.label = this.$t('admin.cart.pezzo')
			// }
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.prodrecap {
	@extend %flexCenterStart;
	width: 100%;
	border-bottom: 1px dotted rgba($color-onyx, 0.2);
	&__col {
		@extend %flexCenterStart;
		padding: var(--thirdgutter);
		&.prod { width: 25%; }
		&.code { width: 25%; }
		&.qta { width: 25%; }
		&.price { width: 10%; }
		&.cta { width: 10%; }
	}
	&__media {
		@extend %flexCenterCenter;
		background: rgba($color-onyx,0.1);
		@include size(var(--supergutter),var(--supergutter));
		border-radius: 100%;
		overflow: hidden;
		position: relative;
		&__image {
			display: block;
			@include size(100%,100%);
			fill: cover;
			position: relative;
			z-index: 1;
		}
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			@include size(40%,40%);
			fill: rgba($color-onyx, 0.2);
			transform: translate(-50%,-50%);
			z-index: 0;
		}
	}
	&__prod, &__code, &__price {
		font-variation-settings: 'wght' 500;
	}
	.cta {
		@extend %flexCenterEnd;
		&__btn {
			@extend %flexCenterCenter;
			@include size(20px,20px);
			margin-left: var(--thirdgutter);
			cursor: pointer;
			svg {
				display: block;
				@include size(100%,100%);
			}
			&.edit {
				margin-left: 0;
				svg {
					fill: $color-black;
				}
			}
			&.delete {
				svg {
					fill: $color-red;
				}
			}
		}
	}
}
</style>
