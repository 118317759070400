<template>
	<div class="panel">
		<div class="panel__head">
			<span class="panel__head__handle"></span>
			<span class="panel__head__close" @click="closePanel">
				<svg viewBox="0 0 50 50">
					<use
						xlink:href="#icon--close"
						width="100%"
						height="100%"
					></use>
				</svg>
			</span>
		</div>
		<div class="panel__content">
			<div class="panel__content__box">
				<div class="panel__block">
					<div v-if="docs && docs.length" class="panel__content__block">
						<span class="panel__content__title">
							{{ $t("app.common.documents") }}
						</span>
						<span class="panel__content__list list">
							<span class="list__item" @click="showDocuments">
								<span class="list__item__icon">
									<svg viewBox="0 0 50 50">
										<use xlink:href="#icon--docs" width="100%" height="100%"></use>
									</svg>
								</span>
								<span class="list__item__label">
									<span class="list__item__label__title">
										{{ $t("app.common.documents") }}
									</span>
								</span>
								<span class="list__item__arrow">
									<svg viewBox="0 0 50 50">
										<use xlink:href="#icon--back-arrow" width="100%" height="100%"></use>
									</svg>
								</span>
							</span>
						</span>
					</div>
					<div v-if="parts && parts.length" class="panel__content__block">
						<span class="panel__content__title">
							{{ $t("app.common.spareparts") }}
						</span>
						<span class="panel__content__list list">
							<ListPreview v-for="p in parts" :key="`part__${p.id}`" :info="p" />
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

import ListPreview from '@/views/app/components/common/ListPreview';

export default {
	name: "Panel",
	components: {
		ListPreview
	},
	data() {
		return {
			docs: [
				{
					id: 0,
					name: "Desiree",
					url: "https://www.africau.edu/images/default/sample.pdf",
				},
				{
					id: 1,
					name: "Vanessa",
					url: "https://www.africau.edu/images/default/sample.pdf",
				},
				{
					id: 2,
					name: "Sellers",
					url: "https://www.africau.edu/images/default/sample.pdf",
				},
				{
					id: 3,
					name: "Howell",
					url: "https://www.africau.edu/images/default/sample.pdf",
				},
				{
					id: 4,
					name: "Figueroa",
					url: "https://www.africau.edu/images/default/sample.pdf",
				},
				{
					id: 5,
					name: "Henson",
					url: "https://www.africau.edu/images/default/sample.pdf",
				},
				{
					id: 6,
					name: "Hays",
					url: "https://www.africau.edu/images/default/sample.pdf",
				},
				{
					id: 7,
					name: "Cruz",
					url: "https://www.africau.edu/images/default/sample.pdf",
				},
				{
					id: 8,
					name: "Stefanie",
					url: "https://www.africau.edu/images/default/sample.pdf",
				},
				{
					id: 9,
					name: "Eloise",
					url: "https://www.africau.edu/images/default/sample.pdf",
				},
				{
					id: 10,
					name: "Christensen",
					url: "https://www.africau.edu/images/default/sample.pdf",
				},
			],
			parts: [
				{
					id: 0,
					name: "Skinner",
					code: "6Moik51ZIzqukqG",
					image: "https://picsum.photos/id/1007/1600/1200",
					planned_quantity: 11,
					price: {
						currency: "EUR",
						value: 501,
					},
				},
				{
					id: 1,
					name: "Marissa",
					code: "HuzWidQSSfOom5X",
					image: "https://picsum.photos/id/1035/1600/1200",
					planned_quantity: 11,
					price: {
						currency: "EUR",
						value: 586,
					},
				},
				{
					id: 2,
					name: "Kitty",
					code: "YOEZAEYNK0SVEjF",
					image: "https://picsum.photos/id/1050/1600/1200",
					planned_quantity: 11,
					price: {
						currency: "EUR",
						value: 161,
					},
				},
				{
					id: 3,
					name: "Minerva",
					code: "LHaoEHlpb8bBler",
					image: "https://picsum.photos/id/1053/1600/1200",
					planned_quantity: 11,
					price: {
						currency: "EUR",
						value: 341,
					},
				},
				{
					id: 4,
					name: "Elsie",
					code: "YMwMpgHlRYLRBh5",
					image: "https://picsum.photos/id/1045/1600/1200",
					planned_quantity: 11,
					price: {
						currency: "EUR",
						value: 240,
					},
				},
				{
					id: 5,
					name: "Page",
					code: "OSRMSoMuh0VIWFW",
					image: "https://picsum.photos/id/1050/1600/1200",
					planned_quantity: 11,
					price: {
						currency: "EUR",
						value: 867,
					},
				},
				{
					id: 6,
					name: "Haney",
					code: "8kkRuWxYQwRyREW",
					image: "https://picsum.photos/id/1067/1600/1200",
					planned_quantity: 11,
					price: {
						currency: "EUR",
						value: 713,
					},
				},
				{
					id: 7,
					name: "Theresa",
					code: "wnK45obcAXxvHWm",
					image: "https://picsum.photos/id/1025/1600/1200",
					planned_quantity: 11,
					price: {
						currency: "EUR",
						value: 823,
					},
				},
				{
					id: 8,
					name: "Tia",
					code: "dMKFN4U4UhFSuSG",
					image: "https://picsum.photos/id/1059/1600/1200",
					planned_quantity: 11,
					price: {
						currency: "EUR",
						value: 891,
					},
				},
				{
					id: 9,
					name: "Robinson",
					code: "MEs24CcNI7uTepE",
					image: "https://picsum.photos/id/1027/1600/1200",
					planned_quantity: 11,
					price: {
						currency: "EUR",
						value: 53,
					},
				},
				{
					id: 10,
					name: "Tamra",
					code: "f1wKGGRX7Zntc79",
					image: "https://picsum.photos/id/1018/1600/1200",
					planned_quantity: 11,
					price: {
						currency: "EUR",
						value: 939,
					},
				},
			],
		};
	},
	methods: {
		closePanel() {
			this.$el.classList.remove("show");
			this.$el.classList.remove("open");
			EventBus.$emit('closing-panel');
		},
		showDocuments() {
			EventBus.$emit('show-docs-panel', this.docs);
		},
		dragPanel(handle) {
			let initpos = 0;
			let box = this.$el.querySelector(".panel__content__box");
			handle.addEventListener("touchstart", (event) => {
				initpos = event.touches[0].clientY;
			});
			handle.addEventListener("touchmove", (event) => {
				if (this.$el.classList.contains("open")) {
					if (initpos - event.touches[0].clientY <= 0) {
						if (box.scrollTop == 0) {
							this.$el.classList.remove("open");
						}
					}
				} else {
					if (initpos - event.touches[0].clientY > 0) {
						this.$el.classList.add("open");
					}
				}
			});
		},
	},
	mounted() {
		this.dragPanel(this.$el);
		EventBus.$on('show-overview-panel', (code, point) => {
			this.$el.classList.add('show')
			this.docs = [];
			this.$service.qr.docs(code, point.id).then(res => {
				console.log('DOCS', res);
				if(res && res.length) {
					res.forEach(r => {
						this.docs.push(r);
					})
				}
			})

			this.parts = [];
			this.$service.qr.parts(code, point.id).then(res => {
				console.log('PARTS', res);
				if(res && res.length) {
					res.forEach(r => {
						this.parts.push(r);
					})
				}
			})
		})

		EventBus.$on('show-prod-panel', () => {
			this.$el.style = 'pointer-events: none;';
		});

		EventBus.$on('close-prod-panel', () => {
			this.$el.style = 'pointer-events: all';
		})
	},
};
</script>
<style lang="scss">
.panel {
	position: fixed;
	top: 100%;
	left: 0;
	display: block;
	background: $color-white;
	@include size(100%, 0);
	border-top-left-radius: var(--radius);
	border-top-right-radius: var(--radius);
	padding: 0 var(--minigutter);
	transition: 350ms linear;
	&__head {
		@extend %flexCenterEnd;
		@include size(100%, 40px);
		position: relative;
		&__handle {
			position: absolute;
			top: 10px;
			left: 50%;
			display: block;
			@include size(40px, 5px);
			background: $color-onyx;
			border-radius: 10px;
			transform: translateX(-50%);
			opacity: 0.64;
			pointer-events: none;
		}
		&__close {
			position: absolute;
			top: 10px;
			right: 0;
			@extend %flexCenterCenter;
			background: rgba($color-black, 0.1);
			@include size(var(--smallicon), var(--smallicon));
			border-radius: 100%;
			svg {
				display: block;
				@include size(50%, 50%);
				fill: $color-black;
				pointer-events: none;
			}
		}
	}
	&__content {
		display: block;
		@include size(100%, var(--panel));
		position: relative;
		transition: 350ms linear;
		overflow: hidden;
		&__box {
			display: block;
			@include size(100%, 100%);
			overflow: hidden;
			scrollbar-width: none;
			&::-webkit-scrollbar {
				display: none;
			}
		}
		&__block {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
		}
		&__title {
			display: block;
			width: 100%;
			@extend %text-20;
			font-family: $font-oswald;
			color: $color-red;
			text-transform: uppercase;
			padding: calc(var(--minigutter) / 2) 0;
		}
		&__list {
			display: block;
			width: 100%;
		}
	}
	&.show {
		top: calc(100vh - var(--panel));
		height: var(--panel);
	}
	&.open {
		top: calc(100vh - var(--panelOpen));
		height: var(--panelOpen);
		.panel__content {
			height: calc(var(--panelOpen) - var(--minigutter));
			&__box {
				overflow: auto;
			}
		}
	}
}
</style>