<template>
    <div id="admin-layout" class="admin-layout">
		<Sidebar v-if="showSidebar" />
		<Head :page="$route.name" />
        <router-view />
		<Footer :page="$route.name" :showLogout="showSidebar" />
		<ConfirmCart />
		<EmptyCart />
		<AlertCart />
    </div>
</template>

<script>
import { EventBus } from '@/events_bus.js';
import Head from '@/views/admin/components/layout/Head';
import Sidebar from '@/views/admin/components/layout/Sidebar';
import Footer from '@/views/admin/components/layout/Footer';
import ConfirmCart from '@/views/admin/components/modals/ConfirmCart';
import AlertCart from '@/views/admin/components/modals/AlertCart';
import EmptyCart from '@/views/admin/components/modals/EmptyCart';

export default {
    name: 'AdminLayout',
	components: {
		Head,
		Sidebar,
		Footer,
		ConfirmCart,
		AlertCart,
		EmptyCart
	},
	data() {
		return {
			showSidebar: false,
		}
	},
	watch: {
		'$route': {
			immediate: true,
			handler(newval, oldval) {
				this.init();
			}
		},
	},
	methods: {
		init() {
			if(this.$route && this.$route.name) {
				if(this.$route.name == 'login.page') {
					this.showSidebar = false;
				} else {
					this.showSidebar = true;
				}
			}
		}
	},
	mounted() {
		this.init();

		this.$service.dashboard.status().then(res => {
			this.$store.state.status.push({
				id: '',
				name: this.$t('admin.dashboard.filters.allstatus')
			})
			for(const key in res) {
				this.$store.state.status.push({
					id: key,
					name: res[key]
				})
			}
		})

		this.$service.dashboard.orders().then(res => {
			localStorage.setItem('total-oreders', res.total);
		})

		this.$service.dashboard.customers().then(res => {
			this.$store.state.customers = [
				{
					id: 0,
					name: this.$t('admin.dashboard.filters.allcustomers')
				}
			];
			res.forEach(r => {
				this.$store.state.customers.push({
					id: r.id,
					name: r.company
				})
			})
		})

		this.$service.dashboard.plants.get().then(res => {
			console.log('PLANTS');
			this.$store.state.plants = [
				{
					id: 0,
					name: this.$t('admin.dashboard.filters.allplants')
				}
			];
			
			res.forEach(r => {
				this.$store.state.plants.push({
					id: r.id,
					name: r.name
				})
			})
		})

		EventBus.$on('orderslist-updated', () => {
			this.$service.dashboard.orders().then(res => {
				localStorage.setItem('total-oreders', res.total);
			})
		});
		
	},
}
</script>

<style lang="scss">
.admin-layout {
	display: block;
	background: $color-seasalt;
	width: 100%;
	min-height: 100vh;
	padding: 0 var(--minigutter);
	@media (min-width: $to-medium) {
		padding: 0;
	}
}
</style>
