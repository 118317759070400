<template>
	<div v-if="info" class="panelprod">
		<div class="panelprod__head">
			<span class="panelprod__head__title">
				{{ $t('app.common.products') }}
			</span>
			<span class="panelprod__head__close" @click="closePanel">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--close" width="100%" height="100%"></use>
				</svg>
			</span>
		</div>
		<div class="panelprod__content">
			<div class="panelprod__content__box prod">
				<figure class="prod__media o-ratio u-16:12">
					<img v-if="info.image" class="prod__media__image" :src="info.image" alt="" @error="hideImage" />
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--logo-e" width="100%" height="100%"></use>
					</svg>
				</figure>
				<div v-if="info && info.name" class="prod__title" v-html="info.name">
				</div>
				<div class="prod__content">
					<div v-if="info && info.code" class="prod__code">
						cod: {{ info.code }}
					</div>
					<div class="prod__info">
						<span class="prod__info__price">
							<small class="prod__info__price__label">
								{{ $t('app.common.price') }}
							</small>
							<span class="prod__info__price__value" v-html="`${info.price.currency ? $options.filters.currency(info.price.currency) : '&euro;' } ${info.price.price}`"></span>
							<small class="prod__info__price__note">
								{{ $t('app.common.novat') }}
							</small>
						</span>
						<span class="prod__info__data">
							{{ $t('app.common.suggested') }}: {{ info.planned_quantity }} {{ $t('app.common.pieces') }}
						</span>
					</div>
					<div class="prod__cta">
						<small class="prod__cta__label">
							{{ $t('app.common.quantity') }}
						</small>
						<span class="prod__cta__qty">
							<input type="number" min="1" v-model="quantity" />
						</span>
						<span class="prod__cta__btns">
							<span class="prod__cta__btns__btn btn red" @click="addToCart" :class="`${quantity > 0 ? '' : 'disabled'}`">
								{{ $t('app.ctas.addtocart') }}
							</span>
							<!-- <span class="prod__cta__btns__btn btn white">
								{{ $t('app.ctas.gotocart') }}
							</span> -->
						</span>
					</div>
				</div>
			</div>
		</div>
		<ModalAddedToCart />
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

import ModalAddedToCart from '../modals/ModalAddedToCart';

export default {
	name: "PanelProduct",
	components: {
		ModalAddedToCart
	},
	data() {
		return {
			info: null,
			show: false,
			prodImage: null,
			quantity: 0
		}
	},
	methods: {
		hideImage(evt) {
			evt.target.classList.add('hide')
		},
		closePanel() {
			let self = document.querySelector('.panelprod');
			let selfimg = self.querySelector('img');
			self.classList.remove('show')
			if(selfimg) {
				selfimg.classList.remove('hide')
			}
			EventBus.$emit('close-prod-panel');
		},
		addToCart() {
			let current = JSON.parse(localStorage.getItem('current'));
			this.info.quantity = +this.quantity;
			this.info.qr = current.code;
			let cart = JSON.parse(localStorage.getItem('cart'));

			let existingProduct = cart.filter(c => {
				return c.id == this.info.id;
			})

			if(existingProduct && existingProduct.length) {
				existingProduct[0].quantity = +existingProduct[0].quantity + +this.info.quantity;
			} else {
				cart.push(this.info);
			}
			
			localStorage.setItem('cart', JSON.stringify(cart));
			EventBus.$emit('cart-update');
			EventBus.$emit('modal-added');
		}
	},
	mounted() {
		EventBus.$on('close-detail', () => {
			this.$el.classList.remove('show');
			setTimeout(() => {
				this.info = null;
			}, 450)
		});
		EventBus.$on('show-prod-panel', (info) => {
			console.log('MOSTRA PRODOTTO');
			this.info = info;
			this.quantity = this.info.planned_quantity;
			setTimeout(() => {
				this.$el.classList.add('show');
			}, 50)
		})
	},
};
</script>
<style lang="scss">
.panelprod {
	position: fixed;
	top: 100%;
	left: 0;
	display: block;
	background: $color-white;
	@include size(100%, 0);
	border-top-left-radius: var(--radius);
	border-top-right-radius: var(--radius);
	transition: 350ms linear;
	pointer-events: none;
	z-index: 1000;
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		padding: var(--gutter) var(--gutter) 0;
		&__title {
			@extend %text-20;
			font-family: $font-oswald;
			color: $color-red;
			text-transform: uppercase;
		}
		&__close {
			@extend %flexCenterCenter;
			background: rgba($color-black, 0.1);
			@include size(var(--smallicon), var(--smallicon));
			border-radius: 100%;
			svg {
				display: block;
				@include size(50%, 50%);
				fill: $color-black;
			}
		}
	}
	&__content {
		display: block;
		@include size(100%, var(--panelFull));
		position: relative;
		transition: 350ms linear;
		padding-top: var(--gutter);
		overflow: hidden;
		&__box {
			display: block;
			@include size(100%, 100%);
			overflow: auto;
			scrollbar-width: none;
			padding-bottom: var(--supergutter);
			&::-webkit-scrollbar {
				display: none;
			}
		}
		&__block {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
		}
		&__list {
			display: block;
			width: 100%;
			.list__item {
				&:first-of-type {
					border-top: 0;
				}
			}
		}
	}
	&.show {
		top: var(--head);
		height: calc(100vh - var(--head));
		box-shadow: 0 0 30px rgba($color-black, 0.3);
		pointer-events: all;
		.panellist__content {
			height: calc(100vh - var(--head));
			&__box {
				overflow: auto;
			}
		}
	}
	.prod {
		&__media {
			width: 100%;
			background: rgba($color-black,0.1);
			img {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				@include size(100%,100%);
				object-fit: cover;
				transition: 180ms linear;
				&.hide {
					opacity: 0;
				}
			}
			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				@include size(var(--icon),var(--icon));
				fill: rgba($color-black, 0.1);
				transform: translate(-50%,-50%);
			}
		}
		&__content {
			display: block;
			width: 100%;
			padding: 0 var(--gutter) calc(var(--supergutter) * 2);
		}
		&__title {
			@extend %flexCenterStart;
			background: $color-red;
			// @include size(90%, var(--ribbon));
			width: 90%;
			min-height: var(--ribbon);
			clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%);
			@extend %text-22;
			line-height: 1.1;
			font-family: $font-oswald;
			color: $color-white;
			padding: 12px var(--gutter);
		}
		&__code, &__info,
		&__cta {
			width: 100%;
			padding: var(--minigutter) 0;
		}
		&__code {
			display: block;
			@extend %text-18;
			text-transform: uppercase;
		}
		&__code, &__info {
			border-bottom: 1px dotted rgba($color-black, 0.3);
		}
		&__info {
			@extend %flexCenterBetween;
			&__price, &__data {
				width: 50%;
			}
			&__price {
				display: flex;
				flex-direction: column;
				&__label {
					@extend %titlelabel;
					@extend %text-15;
				}
				&__value {
					@extend %text-30;
					font-family: $font-oswald;
					font-variation-settings: 'wght' 500;
					color: $color-denim;
					line-height: 1;
				}
				&__note {
					@extend %text-15;
					font-style: italic;
				}
			}
			&__data {
				@extend %text-15;
				font-family: $font-oswald;
				font-variation-settings: 'wght' 500;
				color: $color-onyx;
				text-align: right;
				text-transform: uppercase;
			}
		}
		&__cta {
			@extend %flexStartBetween;
			flex-wrap: wrap;
			&__label {
				@extend %titlelabel;
				@extend %text-15;
				display: block;
				width: 100%;
				margin-bottom: calc(var(--minigutter) / 2);
			}
			&__qty {
				display: block;
				width: 75px;
				input {
					@extend %text-30;
					@include size(100%,var(--input));
					border: 2px solid rgba($color-black, 0.3);
					font-family: $font-oswald;
					font-variation-settings: 'wght' 600;
					color: $color-black;
					text-align: center;
					border-radius: var(--radius);
				}
			}
			&__btns {
				display: block;
				width: calc(100% - 75px);
				padding-left: 5px;
				&__btn {
					@extend %btn;
					@include size(calc(100% - 5px),var(--input));
					&.red {
						@extend %text-18;
						font-weight: 600;
						&.disabled {
							opacity: 0.6;
							pointer-events: none;
						}
					}
					&.white {
						@extend %text-15;
						font-family: $font-oswald;
						font-variation-settings: 'wght' 500;
					}
				}
			}
		}
	}
}
</style>
