<template>
	<div class="modaladmin">
		<div class="modaladmin__box">
			<div class="modaladmin__head">
				<span class="modaladmin__head__redlabel" v-html="$t('admin.cart.modal.confirm.title')"></span>
				<span class="modaladmin__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<template v-if="isLoading">
				<div class="modaladmin__loader">
					<Loader />
				</div>
			</template>
			<template v-else>
				<div class="modaladmin__content">
					<span class="modaladmin__text" v-html="$t('admin.cart.modal.confirm.text')"></span>
					<div class="modaladmin__recap">
						<span class="modaladmin__recap__title" v-html="$t('admin.cart.recap.title')"></span>
						<span class="modaladmin__recap__content">
							<small v-html="$t('admin.cart.recap.customer')"></small>
							<span v-if="customer">
								{{ customer.company }}
							</span>
							<small v-html="$t('admin.cart.recap.plant')"></small>
							<span v-if="plant && plant.name">
								{{ plant.name }}
							</span>
							<small v-html="$t('admin.cart.recap.prods')"></small>
							<span v-if="cart && cart.length">
								{{ cart.length }} {{ cart.length > 1 ? $t('admin.cart.pezzi') : $t('admin.cart.pezzo') }}
							</span>
						</span>
					</div>
				</div>
				<div class="modaladmin__ctas">
					<span class="modaladmin__btn cancel" @click="closeModal">
						{{ $t('admin.cart.modal.confirm.back') }}
					</span>
					<span class="modaladmin__btn confirm" @click="checkoutCart">
						{{ $t('admin.cart.modal.confirm.confirm') }}
					</span>
				</div>
			</template>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';

import Loader from '@/views/admin/components/common/Loader';

export default {
	name: 'ConfirmCart',
	components: {
		Loader
	},
	data() {
		return {
			cart: null,
			sections: null,
			plant: null,
			customer: null,
			isLoading: false,
			orderSent: true,
		}
	},
	methods: {
		init() {
			gsap.timeline()
				.to(this.$el, {
					duration: 0.3,
					opacity: 1,
					pointerEvents: 'all'
				})
				.to(this.$el.querySelector('.modaladmin__box'), {
					duration: 0.6,
					y: 0,
					opacity: 1,
					pointerEvents: 'all'
				})
			

			this.cart = JSON.parse(localStorage.getItem('cart'));
			this.sections = _.groupBy(this.cart, ({ part }) => part)

			this.plant = JSON.parse(localStorage.getItem('current-plant'));
			this.customer = JSON.parse(localStorage.getItem('current-customer'));
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set([this.$el, this.$el.querySelector('.modaladmin__box')], {
						clearProps: 'all'
					})
				}
			})
				.to(this.$el.querySelector('.modaladmin__box'), {
					duration: 0.6,
					y: 100,
					opacity: 0,
					pointerEvents: 'none'
				})
				.to(this.$el, {
					duration: 0.3,
					opacity: 0,
					pointerEvents: 'none'
				})
		},
		checkoutCart() {
			this.isLoading = true;
			this.$service.dashboard.checkout(this.cart).then(res => {
				this.isLoading = false;
				localStorage.removeItem('cart');
				this.$router.push({ name: 'order-confirmed.page'})
				EventBus.$emit('update-cart', [])
				this.closeModal();
			})
		}
	},
	mounted() {
		EventBus.$on('confirm-cart', () => {
			this.init();
		});
	}
}
</script>
<style lang="scss">
@import '~@/assets/scss/components/modals';
.modaladmin {
	&__recap {
		display: block;
		width: 100%;
		padding: 0 var(--supergutter);
		transition: 180ms linear;
		&__title {
			display: block;
			width: 100%;
			@extend %text-24;
			font-family: $font-oswald;
			color: $color-black;
			text-transform: uppercase;
			border-bottom: 1px dotted $color-onyx;
			padding-bottom: var(--thirdgutter);
			margin-bottom: var(--thirdgutter);
		}
		&__content {
			display: block;
			width: 100%;
			small, span {
				display: block;
				width: 100%;
			}
			span {
				margin-bottom: var(--thirdgutter);
			}
		}
		&__totals {
			@extend %flexCenterBetween;
			@extend %text-22;
			font-family: $font-oswald;
			color: $color-black;
			border-top: 1px dotted $color-onyx;
			padding-top: var(--thirdgutter);
			margin-top: var(--thirdgutter);
			margin-bottom: var(--gutter);
		}
	}
	&__loader {
		@extend %flexCenterCenter;
		flex-direction: column;
		width: 100%;
		padding: var(--supergutter);
	}
}
</style>