<template>
	<footer id="foot" class="foot">
		<div v-if="showLogout" class="foot__logo" @click="logout">
			Logout
		</div>
	</footer>
</template>
<script>
export default {
	name: 'Footer',
	props: {
		page: {
			type: String,
			required: true,
		},
		showLogout: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	data() {
		return {
		}
	},
	methods: {
		logout() {
			localStorage.removeItem('current-plant');
			localStorage.removeItem('current-customer');
			localStorage.removeItem('tokentimer');
			localStorage.removeItem('token');
			localStorage.removeItem('cart');
			localStorage.removeItem('user');

			setTimeout(() => {
				this.$router.push({ name: 'login.page' });
			}, 150)
		}
	}
}
</script>
<style lang="scss">
.foot {
	position: fixed;
	bottom: 0;
	left: 0;
	display: block;
	width: 100%;
	padding: var(--minigutter) var(--gutter);
	z-index: 10;
	pointer-events: none;
	&__logo {
		display: block;
		@include size(112px,20px);
		pointer-events: all;
		svg {
			display: block;
			@include size(100%,100%);
			fill: $color-red;
		}
		@extend %text-13;
		font-family: $font-oswald;
		font-variation-settings: 'wght' 500;
		text-transform: uppercase;
		cursor: pointer;
	}
}
</style>
