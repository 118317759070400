import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import api from './plugins/api';
import service from './plugins/service';
import filters from './plugins/filters';

import './registerServiceWorker'

import VueEventBus from "vue-plugin-event-bus";
import VueQrcodeReader from "vue-qrcode-reader";

Vue.config.productionTip = false
Vue.use(api);
Vue.use(service);
Vue.use(VueEventBus);
Vue.use(VueQrcodeReader);

// Vue.mixin({
//     created: function() {
//         this.gsap = gsap;
//     }
// })

filters.forEach(f => {
	Vue.filter(f.name, f.execute);
})

new Vue({
	i18n,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
