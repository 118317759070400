<template>
    <div id="login-layout" class="login-layout">
		<Head :page="$route.name" />
        <router-view />
		<Footer :page="$route.name" :showLogout="false" />
    </div>
</template>

<script>
import { EventBus } from '@/events_bus.js';
import Head from '@/views/admin/components/layout/Head';
import Footer from '@/views/admin/components/layout/Footer';

export default {
    name: 'LoginLayout',
	components: {
		Head,
		Footer
	}
}
</script>

<style lang="scss">
.login-layout {
	display: block;
	background: $color-seasalt;
	width: 100%;
	min-height: 100vh;
	padding: 0 var(--minigutter);
	@media (min-width: $to-medium) {
		padding: 0;
	}
}
</style>
