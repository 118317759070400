<template>
	<span v-if="info" target="_blank" download class="list__item" @click="showDetails">
		<span class="list__item__icon">
			<template v-if="itemType == 'docs'">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--doc" width="100%" height="100%"></use>
				</svg>
			</template>
			<template v-if="itemType == 'parts'">
				<template v-if="info.image != ''">
					<img class="list__item__icon__image" :src="info.image" alt="" />
				</template>
				<template v-else>
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--logo-e" width="100%" height="100%"></use>
					</svg>
				</template>
			</template>
		</span>
		<span class="list__item__label">
			<span v-if="info.name" class="list__item__label__title">
				{{ info.name }}
			</span>
			<small v-if="info.code" class="list__item__label__code">
				cod: {{ info.code }}
			</small>
		</span>
		<span class="list__item__arrow">
			<template v-if="info.price">
				<span v-html="`${info.price.currency ? $options.filters.currency(info.price.currency) : '&euro;' } ${info.price.price}`"></span>
			</template>
			<template v-else>
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--back-arrow" width="100%" height="100%"></use>
				</svg>
			</template>
		</span>
	</span>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'ListPreview',
	props: {
		itemType: {
			type: String,
			required: false,
			default: 'parts'
		},
		info: {
			type: Object,
			required: true,
		}
	},
	methods: {
		showDetails() {
			console.log('PARTS', this.itemType);
			if(this.itemType == 'parts') {
				EventBus.$emit('show-prod-panel', this.info);
			} else {
				window.open(this.info.url);
			}
		}
	}
}
</script>
<style lang="scss">
.list__item {
	@extend %flexCenterStart;
	width: 100%;
	border-top: 1px dotted rgba($color-black, 0.4);
	padding: calc(var(--gutter) / 3) 0;
	color: $color-black;
	position: relative;
	&__icon {
		@extend %flexCenterCenter;
		background: $color-denim;
		@include size(var(--list),var(--list));
		border-radius: 100%;
		overflow: hidden;
		&__image {
			display: block;
			@include size(100%,100%);
			object-fit: cover;
		}
		svg {
			display: block;
			@include size(50%,50%);
			fill: $color-white;
		}
	}
	&__label {
		@extend %flexStartCenter;
		flex-direction: column;
		padding-left: var(--minigutter);
		width: 63%;
		&__title {
			font-weight: 600;
		}
		&__cod {
			@extend %text-13;
		}
	}
	&__arrow {
		position: absolute;
		top: calc(var(--gutter) / 3);
		right: 0;
		@extend %flexCenterEnd;
		@include size(auto,var(--list));
		svg {
			display: block;
			@include size(calc(var(--smallicon) * 0.75),calc(var(--smallicon) * 0.75));
			fill: $color-black;
			transform: scaleX(-1);
		}
	}
}
</style>