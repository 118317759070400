import { render, staticRenderFns } from "./SnackbarDelete.vue?vue&type=template&id=3457505c"
import script from "./SnackbarDelete.vue?vue&type=script&lang=js"
export * from "./SnackbarDelete.vue?vue&type=script&lang=js"
import style0 from "./SnackbarDelete.vue?vue&type=style&index=0&id=3457505c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports