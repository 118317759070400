<template>
	<main v-if="order" id="orderdetail" class="page-admin orderdetail">
		<div class="orderdetail__head">
			<span class="orderdetail__head__back" @click="$router.go(-1)">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--back" width="100%" height="100%"></use>
				</svg>
			</span>
			<span class="orderdetail__head__info">
				<span class="orderdetail__head__ticketid" v-html="order.ticket_id.id"></span>
				<span class="orderdetail__head__company" v-html="order.user.company"></span>
				<span class="orderdetail__head__plant" v-html="order.plant.name"></span>
				<span class="orderdetail__head__prods">
					{{ $t('admin.detail.products') }}
				</span>
				<span class="orderdetail__head__status">
					<span class="orderdetail__head__status__bullet" :class="order.status" v-html="order.statusLabel"></span>
				</span>
			</span>
		</div>
		<div class="orderdetail__content">
			<div class="orderdetail__list">
				<div v-for="(s, sI) in order.data" :key="sI" class="orderdetail__section ordersection">
					<span class="ordersection__title" v-html="s.name">
					</span>
					<div class="ordersection__head sectionhead">
						<span class="sectionhead__col prods" v-html="$t('admin.detail.sectionhead.prods')"></span>
						<span class="sectionhead__col code" v-html="$t('admin.detail.sectionhead.code')"></span>
						<span class="sectionhead__col quantity" v-html="$t('admin.detail.sectionhead.quantity')"></span>
						<span class="sectionhead__col price" v-html="$t('admin.detail.sectionhead.price')"></span>
					</div>
					<div class="ordersection__body">
						<span v-for="p in s.products" :key="p.id" class="ordersection__prod">
							<span class="ordersection__prod__item prods" v-html="p.name"></span>
							<span class="ordersection__prod__item code" v-html="p.id"></span>
							<span class="ordersection__prod__item quantity" v-html="p.qta"></span>
							<span class="ordersection__prod__item price">
								&euro; {{ p.price }}
							</span>
						</span>
					</div>
				</div>
			</div>
			<div class="orderdetail__ctas">
				<!-- <span class="orderdetail__ctas__btn confirm" v-html="$t('admin.detail.btns.confirm')"></span> -->
				<span v-if="order.status == 'draft'" class="orderdetail__ctas__btn cancel" v-html="$t('admin.detail.btns.cancel')" @click="cancelOrder"></span>
				<div v-if="order.attachments && order.attachments.length" class="orderdetail__downloads">
					<small class="orderdetail__downloads__title">
						Downloads:
					</small>
					<span v-for="a in order.attachments" :key="a.id" class="orderdetail__downloads__item" @click="downloadFile(a)">
						<span class="orderdetail__downloads__item__icon">
							<svg viewBox="0 0 50 50">
								<use xlink:href="#icon--pdf" width="100%" height="100%"></use>
							</svg>
						</span>
						<span class="orderdetail__downloads__item__label" v-html="a.nome">
						</span>
					</span>
				</div>
			</div>
		</div>
		<ConfirmDelete />
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import dayjs from 'dayjs';

import ConfirmDelete from '@/views/admin/components/modals/ConfirmDelete.vue';

export default {
	name: 'OrderDetail',
	components: {
		ConfirmDelete
	},
	data() {
		return {
			order: null,
		}
	},
	methods: {
		init() {
			if(this.$route && this.$route.params && this.$route.params.id) {
				this.$service.dashboard.orderDetail(this.$route.params.id).then(res => {
					this.order = res;
					this.order.formatted_date = dayjs(this.order.date).format('DD/MM/YY');
					if(this.$store && this.$store.state && this.$store.state.status) {
						let item = this.$store.state.status.filter(s => {
							return s.id == this.order.status;
						})
						if(item && item.length) {
							this.order.statusLabel = item[0].name;
						}
					}
					this.$forceUpdate();
				})
			}
		},
		cancelOrder() {
			EventBus.$emit('cancel-order', this.order.id);
		},
		downloadFile(item) {
			const base64String = `data:application/pdf;base64,${item.base64}`;
			fetch(base64String).then(res => res.blob())
        		.then(blob => {
					const link = document.createElement('a');
					let url = new File([blob], item.nome, { type: "application/pdf" })
					link.href = URL.createObjectURL(url);
					link.download = item.nome;
					document.body.append(link);
					link.click();
					link.remove();
            		return
        		})

		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.orderdetail {
	height: 100vh;
	overflow: hidden;
	position: relative;
	&__head, &__content {
		padding-left: var(--gutter);
		padding-right: var(--gutter);
	}
	&__head {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterBetween;
		@include size(100%, calc(var(--supergutter) * 1.5));
		padding-top: var(--gutter);
		z-index: 10;
		&__back {
			@extend %flexCenterStart;
			@include size(var(--smallicon),var(--smallicon));
			cursor: pointer;
			svg {
				display: block;
				@include size(60%,60%);
				fill: $color-black;
			}
		}
		&__info {
			@extend %flexCenterStart;
			@include size(calc(100% - var(--smallicon)), var(--smallicon));
		}
		&__ticketid, &__company,
		&__plant, &__prods {
			display: block;
			width: max-content;
			margin-right: var(--supergutter);
			@extend %text-17;
			font-family: $font-oswald;
			font-variation-settings: 'wght' 500;
		}
		&__ticketid {
			@extend %text-24;
			font-variation-settings: 'wght' 500;
			font-family: $font-oswald
		}
		&__status {
			&__bullet {
				@extend %flexCenterCenter;
				height: 25px;
				border-radius: 12px;
				color: $color-white;
				@extend %text-13;
				padding: 0 var(--minigutter);
				&.cancel { background: $color-cancel; }
				&.done { background: $color-done; }
				&.draft { background: $color-draft; }
				&.open { background: $color-open; }
				&.pending { background: $color-pending; }
			}
		}
	}
	&__content {
		@extend %flexStartBetween;
		width: 100%;
		height: calc(100% - ((var(--supergutter) * 1.5)));
		margin: calc(var(--supergutter) * 1.5) 0 0;
		overflow: auto;
	}
	&__list {
		display: block;
		width: 75%;
		margin: var(--gutter) 0;
		padding-right: var(--minigutter);
	}
	&__ctas {
		display: block;
		width: 25%;
		margin: var(--gutter) 0;
		padding-left: var(--minigutter);
		&__btn {
			@extend %flexCenterCenter;
			@include size(100%, var(--smallinput));
			border-radius: var(--radius);
			color: $color-white;
			margin-bottom: var(--minigutter);
			@extend %text-15;
			font-weight: 600;
			text-transform: uppercase;
			cursor: pointer;
			&.confirm {
				background: $color-success;
			}
			&.cancel {
				background: $color-error;
			}
		}
	}
	&__section {
		display: block;
		width: 100%;
		background: $color-white;
		box-shadow: -5px 5px 10px rgba($color-onyx, 0.2);
		border-radius: var(--radius);
		margin-bottom: var(--minigutter);
		padding: var(--gutter);
		.ordersection {
			&__title, &__head,
			&__body {
				width: 100%;
			}
			&__title {
				@extend %text-20;
				display: block;
				font-family: $font-oswald;
				font-variation-settings: 'wght' 500;
				text-transform: uppercase;
				margin-bottom: calc(var(--minigutter) * 1.5);
			}
			&__head {
				@extend %flexBaselineStart;
				position: relative;
				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: calc(var(--gutter) * -1);
					width: calc(100% + var(--supergutter));
					border-top: 1px dotted rgba($color-onyx, 0.4);
				}
				.sectionhead {
					&__col {
						display: block;
						@extend %text-14;
						height: var(--smallicon);
						font-weight: 500;
						&.prods { width: 27%; }
						&.code { width: 27%; }
						&.quantity { width: 27%; }
						&.price { width: 18%; }
					}
				}
			}
			&__body {
				display: block;
				.ordersection {
					&__prod {
						@extend %flexCenterStart;
						width: 100%;
						padding: var(--minigutter) 0;
						&__item {
							display: block;
							padding-right: var(--minigutter);
							@extend %text-16;
							font-variation-settings: 'wght' 500;
							&.prods { width: 27%; }
							&.code { width: 27%; }
							&.quantity { width: 27%; }
							&.price { width: 18%; }
						}
					}
				}
			}
		}
	}
	&__downloads {
		display: block;
		width: 100%;
		margin-top: var(--gutter);
		&__title {
			display: block;
			width: 100%;
			@extend %text-15;
			font-weight: 600;
		}
		&__item {
			@extend %flexCenterStart;
			width: 100%;
			margin: var(--thirdgutter) 0;
			cursor: pointer;
			&__icon, &__label {
				pointer-events: none;
			}
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--gutter),var(--gutter));
				svg {
					display: block;
					@include size(100%,100%);
					fill: $color-red;
				}
			}
			&__label {
				display: block;
				width: calc(100% - (var(--gutter) + var(--thirdgutter)));
				@extend %text-15;
				color: $color-black;
				margin-left: var(--thirdgutter);
				position: relative;
				&:after {
					content: '';
					position: absolute;
					top: 100%;
					left: 0;
					display: block;
					background: $color-red;
					@include size(100%, 2px);
					transition: transform 0.5s;
					transform: scaleX(0);
					transform-origin: right;
				}
			}
			&:hover {
				.orderdetail__downloads__item__label {
					&:after {
						transform: scaleX(1);
						transform-origin: left;
					}
				}
			}
		}
	}
}
</style>
