<template>
	<main id="dashboard" class="page-admin dashboard">
		<div class="dashboard__head">
			<span class="dashboard__head__title">
				<span class="dashboard__head__title__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--docs" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="dashboard__head__title__label">
					{{ $t('admin.dashboard.title') }}
				</span>
			</span>
			<span class="dashboard__head__utils">
				<CustomSelect v-if="$store && $store.state && $store.state.customers && $store.state.customers.length" :options="$store.state.customers" :default="$store.state.customers[0].name" :tabindex="1" filter="customer" />
				<CustomSelect v-if="$store && $store.state && $store.state.customers && $store.state.plants.length" :options="$store.state.plants" :default="$store.state.plants[0].name" :tabindex="1" filter="plants" />
				<CustomSelect v-if="$store && $store.state && $store.state.customers && $store.state.status.length" :options="$store.state.status" :default="$store.state.status[0].name" :tabindex="1" filter="status" />
			</span>
		</div>
		<div class="dashboard__content">
			<OrdersTable />
		</div>
		<div class="dashboard__utils">
			<OrdersPagination />
			<OrdersPerPage />
		</div>
	</main>	
</template>
<script>
import { EventBus } from '@/events_bus.js';

import OrdersTable from '@/views/admin/components/orders/OrdersTable';
import OrdersPagination from '@/views/admin/components/orders/OrdersPagination';
import OrdersPerPage from '@/views/admin/components/orders/OrdersPerPage';

import CustomSelect from '@/views/admin/components/common/CustomSelect';

export default {
	name: 'Dashboard',
	components: {
		CustomSelect,
		OrdersTable,
		OrdersPagination,
		OrdersPerPage
	},
	data() {
		return {
			orders: null,
		}
	},
	methods: {
		sortResults() {

		},
		init() {
			
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.dashboard {
	height: 100vh;
	overflow: hidden;
	position: relative;
	&__head, &__content, &__utils {
		padding-left: var(--gutter);
		padding-right: var(--gutter);
	}
	&__head {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterBetween;
		@include size(100%, calc(var(--supergutter) * 1.5));
		padding-top: var(--gutter);
		// padding-bottom: var(--minigutter);
		z-index: 10;
		&__title {
			@extend %flexCenterStart;
			width: max-content;
			&__icon {
				@extend %flexCenterStart;
				@include size(var(--icon),var(--icon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-red;
				}
			}
			&__label {
				display: block;
				width: calc(100% - var(--icon));
				@extend %text-24;
				font-family: $font-oswald;
				font-variation-settings: 'wght' 500;
				text-transform: uppercase;
			}
		}
		&__utils {
			@extend %flexCenterEnd;
			.customselect {
				@include size(250px, 40px);
				margin-left: var(--minigutter);
				&__selected {
					justify-content: space-between;
				}
				&__items,
				&__item {
					justify-content: flex-start;
					text-align: left;
				}
				&__items {
					padding: 0 10px;
				}
			}
		}
	}
	&__content {
		display: block;
		width: 100%;
		height: calc(100% - ((var(--supergutter) * 1.5) + var(--utils)));
		margin: calc(var(--supergutter) * 1.5) 0 var(--utils);
		overflow: auto;
	}
	&__utils {
		position: absolute;
		bottom: 0;
		left: 0;
		display: block;
		@include size(100%,var(--utils));
		z-index: 10;
	}
}
</style>