<template>
	<main id="qrscan" class="page qrscan">
		<qrcode-stream class="qrscan__box" @decode="onDecode" @init="onInit"></qrcode-stream>
		<template v-if="scanPermission">
			<span class="qrscan__placeholder">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--qrtarget" width="100%" height="100%"></use>
				</svg>
				<span class="qrscan__placeholder__label">
					{{ $t('app.common.scan') }}
				</span>
			</span>
		</template>
		<template v-else>
			<span class="qrscan__disabled">
				<span class="qrscan__disabled__title" v-html="$t('app.scan.disabled.title')"></span>
				<span class="qrscan__disabled__text" v-html="$t('app.scan.disabled.text')"></span>
			</span>
		</template>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";

export default {
	name: "QRScan",
	components: {
		QrcodeCapture,
		QrcodeDropZone,
		QrcodeStream,
	},
	data() {
		return {
			scanResult: null,
			scanPermission: false
		}
	},
	methods: {
		async onInit(promise) {
			try {
				const { capabilites } = await promise;
				EventBus.$emit('loading', false);
				this.scanPermission = true;
			} catch(error) {
				console.log('E ER ERR', error);
				EventBus.$emit('loading', false);
				this.scanPermission = false;
			}
		},
		async onDecode(result) {
			console.log("DECODED");
			
			const params = result.split('/').reverse();

			let code = '';
			let customer = '';
			if(params && params.length) {
				code = params[0];
				customer = params[1];
				if(params.includes('list')) {
					this.$router.push({ name: 'list.page', params: { customer: customer, code: code } })
				} else {
					this.$router.push({ name: 'plant.page', params: { code: code } })
					localStorage.setItem('currentQR', code);
				}
				
			} else {
				console.error('ERROR QR READ');
			}
		}
	},
	mounted() {
		console.log('SCAN');
		EventBus.$emit('loading', true);
	}
};
</script>
<style lang="scss">
.qrscan {
	display: block;
	@include size(100%, 100vh);
	overflow: hidden;
	&__box {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		@include size(100%, 100%);
		z-index: 2;
	}
	&__placeholder {
		position: absolute;
		top: 50%;
		left: 50%;
		@include size(244px,244px);
		transform: translate(-50%,-50%);
		z-index: 4;
		svg {
			display: block;
			@include size(100%,100%);
			fill: $color-white;
		}
		&__label {
			position: absolute;
			top: 100%;
			left: 0;
			display: block;
			width: 100%;
			@extend %text-18;
			font-weight: 400;
			color: $color-white;
			text-align: center;
			margin-top: var(--gutter);
		}
	}
	&__disabled {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterCenter;
		@include size(100%,100vh);
		flex-direction: column;
		&__title, &__text {
			display: block;
			width: 100%;
			text-align: center;
		}
		&__title {
			font-family: $font-oswald;
			color: $color-red;
			text-transform: uppercase;
			@extend %text-30;
		}
		&__text {
			padding: var(--gutter);
			@extend %text-20;
		}
		z-index: 100;
	}
}
</style>
