<template>
	<div class="modaladmin">
		<div class="modaladmin__box">
			<div class="modaladmin__head">
				<span class="modaladmin__head__redlabel" v-html="$t('admin.detail.cancel.title')"></span>
				<span class="modaladmin__head__close" @click="closeModal">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--close" width="100%" height="100%"></use>
					</svg>
				</span>
			</div>
			<div class="modaladmin__content">
				<span class="modaladmin__content__text" v-html="$t('admin.detail.cancel.text')"></span>
			</div>
			<template v-if="isLoading">
				<div class="modaladmin__loader">
					<Loader />
				</div>
			</template>
			<template v-else>
				<div class="modaladmin__ctas">
					<span class="modaladmin__btn cancel" @click="closeModal">
						{{ $t('admin.detail.cancel.btns.back') }}
					</span>
					<span class="modaladmin__btn delete" @click="confirmDelete">
						{{ $t('admin.detail.cancel.btns.confirm') }}
					</span>
				</div>
			</template>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';

import Loader from '@/views/admin/components/common/Loader.vue';

export default {
	name: 'ConfirmDelete',
	components: {
		Loader
	},
	data() {
		return {
			orderId: null,
			isLoading: false,
		}
	},
	methods: {
		init() {
			gsap.timeline()
				.to(this.$el, {
					duration: 0.3,
					opacity: 1,
					pointerEvents: 'all'
				})
				.to(this.$el.querySelector('.modaladmin__box'), {
					duration: 0.6,
					y: 0,
					opacity: 1,
					pointerEvents: 'all'
				})
		},
		closeModal() {
			gsap.timeline({
				onComplete: () => {
					gsap.set([this.$el, this.$el.querySelector('.modaladmin__box')], {
						clearProps: 'all'
					})
				}
			})
				.to(this.$el.querySelector('.modaladmin__box'), {
					duration: 0.6,
					y: 100,
					opacity: 0,
					pointerEvents: 'none'
				})
				.to(this.$el, {
					duration: 0.3,
					opacity: 0,
					pointerEvents: 'none'
				})
		},
		confirmDelete() {
			this.isLoading = true;
			this.$service.dashboard.cancelOrder(this.orderId).then(res => {
				this.isLoading = false;
				this.closeModal()
				this.$router.push({ name: 'dashboard.page'})
			});
		}
	},
	mounted() {
		EventBus.$on('cancel-order', (orderid) => {
			this.orderId = orderid;
			this.init();
		});
	}
}
</script>
<style lang="scss">
@import '~@/assets/scss/components/modals';
.modaladmin {
	&__recap {
		display: block;
		width: 100%;
		padding: 0 var(--supergutter);
		transition: 180ms linear;
		&__title {
			display: block;
			width: 100%;
			@extend %text-24;
			font-family: $font-oswald;
			color: $color-black;
			text-transform: uppercase;
			border-bottom: 1px dotted $color-onyx;
			padding-bottom: var(--thirdgutter);
			margin-bottom: var(--thirdgutter);
		}
		&__content {
			display: block;
			width: 100%;
			small, span {
				display: block;
				width: 100%;
			}
			span {
				margin-bottom: var(--thirdgutter);
			}
		}
		&__totals {
			@extend %flexCenterBetween;
			@extend %text-22;
			font-family: $font-oswald;
			color: $color-black;
			border-top: 1px dotted $color-onyx;
			padding-top: var(--thirdgutter);
			margin-top: var(--thirdgutter);
			margin-bottom: var(--gutter);
		}
	}
	&__loader {
		@extend %flexCenterCenter;
		flex-direction: column;
		width: 100%;
		padding: var(--supergutter);
	}
}
</style>
