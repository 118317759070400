<template>
	<div class="modal" :class="{'show': show}">
		<div class="modal__box">
			<div class="modal__box__title" v-html="$t('app.common.recap.confirm.title')"></div>
			<div class="modal__box__ctas">
				<span class="modal__box__ctas__btn btn blue" @click="gotoScan">
					{{ $t('app.ctas.newscan') }}
				</span>
			</div>
			<small class="modal__box__label" v-html="$t('app.common.recap.confirm.text')"></small>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'ModalConfirmOrder',
	data() {
		return {
			show: false,
		}
	},
	methods: {
		gotoScan() {
			this.show = false;
			this.$router.push({ name: 'app.page' });
		}
	},
	mounted() {
		EventBus.$on('confirm-order', () => {
			this.show = true;
		})
	}
}
</script>
<style lang="scss">
.modal {
	position: fixed;
	top: 0;
	left: 0;
	@extend %flexCenterCenter;
	background: rgba($color-black, 0.8);
	@include size(100%,100vh);
	opacity: 0;
	pointer-events: none;
	z-index: 1000;
	&__box {
		display: block;
		background: $color-white;
		width: 90%;
		max-height: 90vh;
		padding: var(--gutter) var(--minigutter);
		border-radius: var(--radius);
		position: relative;
		overflow-y: auto;
		transform: translateY(100px);
		opacity: 0;
		&__title {
			@extend %text-24;
			display: block;
			width: 80%;
			font-family: $font-oswald;
			font-variation-settings: 'wght' 500;
			color: $color-denim;
			text-align: center;
			text-transform: uppercase;
			margin: 0 auto var(--gutter);
		}
		&__label {
			@extend %text-13;
			display: block;
			width: 100%;
			color: $color-grey;
			font-weight: 500;
			text-align: center;
			margin: var(--gutter) 0 0;
		}
		&__ctas {
			@extend %flexCenterCenter;
			width: 100%;
			margin-top: var(--minigutter);
			&__btn {
				height: calc(var(--btn) * 0.75);
				text-align: center;
				&.blue {
					padding-top: 6px;
					padding-bottom: 6px;
					padding-left: var(--minigutter);
					padding-right: var(--minigutter);
					margin: 0 !important;
				}
			}
		}
	}
	&.show {
		opacity: 1;
		pointer-events: all;
		.modal__box {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
</style>