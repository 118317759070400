import axios from 'axios';

const API_URL = (process.env.VUE_APP_API_URL !== undefined && process.env.VUE_APP_API_URL !== "") ? process.env.VUE_APP_API_URL : '{{ VUE_APP_API_URL }}';

export default {
	install: function (Vue) {
		// global $http object
		Object.defineProperty(Vue.prototype, '$http', {value: axios});
		// axios settings
		axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
		// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
		//axios.defaults.withCredentials = true;

		const buildErrors = (status, data) => {
			return {
				status: status,
				messages: data
			};
		}

		/**
		 * build response
		 * @param response
		 * @param resolve
		 * @param reject
		 * @param token
		 * @param redirectUnauthorized
		 */
		const buildResponse = (response, resolve, reject) => {
			let data = response.data || "";
			let status = response.status || null;
			if (status == 200) {
				resolve(data);
			}
			if (status != 200) {
				reject(buildErrors(status, data));
			}
		};
		/**
		 * set base header
		 */
		const getBaseHeaders = () => {
			let token = localStorage.getItem('token');
			return {
				validateStatus: () => {
					return true;
				},
				headers: {
					Authorization: `${(token) ? `Bearer ${token}` : ``}`
				}
			}
		}
		const api = {
			/**
			 * @param email
			 * @param password
			 * @returns {Promise<any>}
			 */
			login (email, password) {
				localStorage.removeItem('username');
				localStorage.removeItem('token');
				return new Promise((resolve, reject) => {
					axios.post(`${API_URL}/login`, {
						email: email.trim().toLowerCase(),
						password: password.trim()
					}, {
						validateStatus: () => {
							return true;
						}
					}).catch(() => {
						reject(buildErrors(500, ['general-login-error']));
					}).then((result) => {
						buildResponse(result, (r) => {
							localStorage.setItem('token', r.token);
							localStorage.setItem('username', r.name);
							resolve(r);
						}, reject);
					});
				});
			},
			/**
			 * get
			 * @param resource
			 * @param data
			 * @returns {Promise<unknown>}
			 */
			get (resource, data) {
				let config = getBaseHeaders();
				if (data) {
					config.data = data;
				}
				return new Promise((resolve, reject) => {
					axios.get(`${API_URL}${resource}`, config)
						.catch((error) => {
							reject({
								error: error
							});
						})
						.then((result) => {
							buildResponse(result, resolve, reject);
						});
				});
			},
			/**
			 * post
			 * @param resource
			 * @param data
			 * @returns {Promise<unknown>}
			 */
			post (resource, data, headers) {
				let config = getBaseHeaders();
				headers = headers || null;
				if(headers) {
					Object.keys(headers).map(h => {
						config.headers[h] = headers[h];
					})
				}
				return new Promise((resolve, reject) => {
					axios.post(`${API_URL}${resource}`, data, config)
						.catch((error) => {
							reject({
								error: error
							});
						}).then((result) => {
						buildResponse(result, resolve, reject);
					});
				});
			},
			/**
			 * postMedia
			 * @param resource
			 * @param data
			 * @returns {Promise<unknown>}
			 */
			postMedia (resource, data, headers) {
				let config = getBaseHeaders();
				headers = headers || null;
				if(headers) {
					Object.keys(headers).map(h => {
						config.headers[h] = headers[h];
					})
					config.headers["Content-Type"] = "multipart/form-data";

					console.log('CONFIG', config)
				}
				return new Promise((resolve, reject) => {
					axios.post(`${API_URL}${resource}`, data, config)
						.catch((error) => {
							reject({
								error: error
							});
						}).then((result) => {
						buildResponse(result, resolve, reject);
					});
				});
			},
			/**
			 * put
			 * @param resource
			 * @param data
			 * @returns {Promise<unknown>}
			 */
			put (resource, data) {
				let config = getBaseHeaders();
				return new Promise((resolve, reject) => {
					axios.put(`${API_URL}${resource}`, data, config)
						.catch((error) => {
							reject({
								error: error
							});
						}).then((result) => {
						buildResponse(result, resolve, reject);
					});
				});
			},
			/**
			 * delete
			 * @param resource
			 * @param data
			 * @returns {Promise<unknown>}
			 */
			delete (resource, data) {
				let config = getBaseHeaders();
				if (data) {
					config.data = data;
				}
				return new Promise((resolve, reject) => {
					axios.delete(`${API_URL}${resource}`, config)
						.catch((error) => {
							reject({
								error: error
							});
						})
						.then((result) => {
							buildResponse(result, resolve, reject);
						});
				});
			}
		};
		Vue.prototype.$api = api;
	}
}
