<template>
	<div class="modal" :class="{'show': show}">
		<div v-if="prod" class="modal__box">
			<span class="modal__box__close" @click="closeModal">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--close" width="100%" height="100%"></use>
				</svg>
			</span>
			<div class="modal__box__info prod">
				<figure class="prod__media">
					<img v-if="prod.image" class="prod__media__image" :src="prod.image" alt="" />
				</figure>
				<span class="prod__data">
					<span class="prod__data__label" v-html="prod.name"></span>
					<span class="prod__data__code">
						cod: {{ prod.code }}
					</span>
				</span>
			</div>
			<div class="modal__box__input">
				<input type="number" min="1" class="modal__box__input__number" v-model="prod.quantity" />
				<small>
					{{ $t('app.common.quantity') }}
				</small>
			</div>
			<div class="modal__box__ctas">
				<span class="modal__box__ctas__btn btn green" @click="confirmChange">
					{{ $t('app.ctas.confirmquantity') }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'ModalEditProduct',
	data() {
		return {
			show: false,
			prod: null,
		}
	},
	methods: {
		closeModal() {
			this.show = false;
		},
		confirmChange() {
			EventBus.$emit('update-quantity', this.prod);
			this.show = false;
		}
	},
	mounted() {
		EventBus.$on('edit-product-quantity', prod => {
			this.show = true;
			this.prod = prod;
		})
	}
}
</script>
<style lang="scss">
.modal {
	position: fixed;
	top: 0;
	left: 0;
	@extend %flexCenterCenter;
	background: rgba($color-black, 0.8);
	@include size(100%,100vh);
	opacity: 0;
	pointer-events: none;
	z-index: 1000;
	&__box {
		display: block;
		background: $color-white;
		width: 90%;
		max-height: 90vh;
		padding: var(--gutter);
		border-radius: var(--radius);
		position: relative;
		overflow-y: auto;
		transform: translateY(100px);
		opacity: 0;
		&__close {
			position: absolute;
			top: var(--minigutter);
			right: var(--minigutter);
			@extend %flexCenterCenter;
			background: rgba($color-timberwolf, 0.5);
			@include size(var(--smallicon),var(--smallicon));
			border-radius: 100%;
			svg {
				display: block;
				@include size(50%,50%);
			}
		}
		&__icon {
			@extend %flexCenterCenter;
			@include size(100%,var(--icon));
			margin-top: var(--gutter);
			svg {
				display: block;
				@include size(var(--icon),var(--icon));
				fill: $color-black;
			}
		}
		&__text {
			@extend %text-18;
			display: block;
			width: 100%;
			font-weight: 500;
			text-align: center;
			margin: calc(var(--minigutter) * 1.5) 0 var(--supergutter);
		}
		&__ctas {
			@extend %flexCenterCenter;
			width: 100%;
			margin-top: var(--minigutter);
			&__btn {
				margin: 0 10px;
				height: calc(var(--btn) * 0.75) !important;
			}
		}
		&__info {
			@extend %flexCenterStart;
			width: 100%;
			margin-bottom: var(--minigutter);
			.prod {
				&__media {
					display: block;
					@include size(var(--list),var(--list));
					border-radius: 100%;
					overflow: hidden;
					&__image {
						display: block;
						@include size(100%,100%);
						object-fit: cover;
					}
				}
				&__data {
					display: block;
					width: calc(100% - var(--list));
					padding-left: var(--minigutter);
					&__label,
					&__code {
						display: block;
						width: 100%;
					}
				}
			}
		}
		&__input {
			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: var(--gutter);
			&__number {
				@extend %text-30;
				display: block;
				@include size(calc(var(--input) * 1.25),var(--input));
				border: 2px solid rgba($color-black, 0.3);
				font-family: $font-oswald;
				font-variation-settings: 'wght' 600;
				color: $color-black;
				text-align: center;
				border-radius: var(--radius);
				margin: 0 auto;
			}
			&__label {
				@extend %text-13;
				display: block;
				width: 100%;
				text-align: center;
				color: $color-onyx;
			}
		}
	}
	&.show {
		opacity: 1;
		pointer-events: all;
		.modal__box {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
</style>