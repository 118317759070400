<template>
	<div class="panellist">
		<div class="panellist__head">
			<span class="panellist__head__title">
				{{ $t('app.common.documents') }}
			</span>
			<span class="panellist__head__close" @click="closePanel">
				<svg viewBox="0 0 50 50">
					<use xlink:href="#icon--close" width="100%" height="100%"></use>
				</svg>
			</span>
		</div>
		<div class="panellist__content">
			<div class="panellist__content__box">
				<div class="panellist__block">
					<div class="panellist__content__block">
						<span class="panellist__content__list list">
							<ListPreview v-for="d in docs" :key="`doc__${d.id}`" :info="d" itemType="docs" />
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

import ListPreview from '@/views/app/components/common/ListPreview';

export default {
	name: "Panel",
	components: {
		ListPreview
	},
	data() {
		return {
			docs: null,
			// docs: [
			// 	{
			// 		id: 0,
			// 		name: "Desiree",
			// 		url: "https://www.africau.edu/images/default/sample.pdf",
			// 	},
			// 	{
			// 		id: 1,
			// 		name: "Vanessa",
			// 		url: "https://www.africau.edu/images/default/sample.pdf",
			// 	},
			// 	{
			// 		id: 2,
			// 		name: "Sellers",
			// 		url: "https://www.africau.edu/images/default/sample.pdf",
			// 	},
			// 	{
			// 		id: 3,
			// 		name: "Howell",
			// 		url: "https://www.africau.edu/images/default/sample.pdf",
			// 	},
			// 	{
			// 		id: 4,
			// 		name: "Figueroa",
			// 		url: "https://www.africau.edu/images/default/sample.pdf",
			// 	},
			// 	{
			// 		id: 5,
			// 		name: "Henson",
			// 		url: "https://www.africau.edu/images/default/sample.pdf",
			// 	},
			// 	{
			// 		id: 6,
			// 		name: "Hays",
			// 		url: "https://www.africau.edu/images/default/sample.pdf",
			// 	},
			// 	{
			// 		id: 7,
			// 		name: "Cruz",
			// 		url: "https://www.africau.edu/images/default/sample.pdf",
			// 	},
			// 	{
			// 		id: 8,
			// 		name: "Stefanie",
			// 		url: "https://www.africau.edu/images/default/sample.pdf",
			// 	},
			// 	{
			// 		id: 9,
			// 		name: "Eloise",
			// 		url: "https://www.africau.edu/images/default/sample.pdf",
			// 	},
			// 	{
			// 		id: 10,
			// 		name: "Christensen",
			// 		url: "https://www.africau.edu/images/default/sample.pdf",
			// 	},
			// ],
		};
	},
	methods: {
		closePanel() {
			this.$el.classList.remove("show");
		},
	},
	mounted() {
		this.docs = [];
		EventBus.$on('show-docs-panel', (list) => {
			console.log('LIST', list);
			if(list && list.length) {
				this.docs = list;
			}
			this.$el.classList.add('show');
		})
	},
};
</script>
<style lang="scss">
.panellist {
	position: fixed;
	top: 100%;
	left: 0;
	display: block;
	background: $color-white;
	@include size(100%, 0);
	border-top-left-radius: var(--radius);
	border-top-right-radius: var(--radius);
	padding: 0 var(--gutter);
	transition: 350ms linear;
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		padding: var(--gutter) 0 var(--minigutter);
		&__title {
			@extend %text-20;
			font-family: $font-oswald;
			color: $color-red;
			text-transform: uppercase;
		}
		&__close {
			@extend %flexCenterCenter;
			background: rgba($color-black, 0.1);
			@include size(var(--smallicon), var(--smallicon));
			border-radius: 100%;
			svg {
				display: block;
				@include size(50%, 50%);
				fill: $color-black;
			}
		}
	}
	&__content {
		display: block;
		@include size(100%, var(--panelFull));
		position: relative;
		transition: 350ms linear;
		padding-top: var(--gutter);
		overflow: hidden;
		&__box {
			display: block;
			@include size(100%, 100%);
			overflow: hidden;
			scrollbar-width: none;
			padding-bottom: var(--supergutter);
			&::-webkit-scrollbar {
				display: none;
			}
		}
		&__block {
			display: block;
			width: 100%;
			margin-bottom: var(--gutter);
		}
		&__list {
			display: block;
			width: 100%;
			.list__item {
				&:first-of-type {
					border-top: 0;
				}
			}
		}
	}
	&.show {
		top: var(--head);
		height: calc(100vh - var(--head));
		box-shadow: 0 0 30px rgba($color-black, 0.3);
		.panellist__content {
			height: calc(100vh - var(--head));
			&__box {
				overflow: auto;
			}
		}
	}
}
</style>