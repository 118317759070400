<template>
	<main id="cartadmin" class="page-admin cartadmin">
		<div class="cartadmin__head">
			<span class="cartadmin__head__title">
				<span class="cartadmin__head__title__icon">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--cart" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="cartadmin__head__title__label">
					{{ $t('admin.cart.title') }}
				</span>
			</span>
			<span v-if="cart" class="cartadmin__head__info">
				<span v-if="customer && customer.length" class="cartadmin__head__item" v-html="customer[0].company"></span>
				<span v-if="plant" class="cartadmin__head__item" v-html="plant.name"></span>
				<span class="cartadmin__head__item">
					{{ cart.length }} {{ $t('admin.detail.products') }}
				</span>
			</span>
		</div>
		<div class="cartadmin__content">
			<template v-if="cart && cart.length">
				<div class="cartadmin__list">
					<div v-for="(value, key) in sections" :key="key" class="cartadmin__section">
						<span class="cartadmin__section__title">
							Section {{ value[0].partName }}
						</span>
						<div class="cartadmin__section__head">
							<span class="cartadmin__section__head__col prod" v-html="$t('admin.neworder.head.prod')"></span>
							<span class="cartadmin__section__head__col code" v-html="$t('admin.neworder.head.code')"></span>
							<span class="cartadmin__section__head__col qta" v-html="$t('admin.neworder.head.qta')"></span>
							<span class="cartadmin__section__head__col price" v-html="$t('admin.neworder.head.price')"></span>
							<span class="cartadmin__section__head__col cta"></span>
						</div>
						<div class="cartadmin__section__list">
							<ProductRecap v-for="v in value" :key="v.id" :p="v" />
						</div>
					</div>
				</div>
				<div class="cartadmin__recap">
					<div class="recapbox">
						<span class="recapbox__title" v-html="$t('admin.cart.recap.title')"></span>
						<span class="recapbox__list">
							<small v-html="$t('admin.cart.recap.customer')"></small>
							<span v-if="customer">
								{{ customer.company }}
							</span>
							<small v-html="$t('admin.cart.recap.plant')"></small>
							<span v-if="plant && plant.name">
								{{ plant.name }}
							</span>
							<small v-html="$t('admin.cart.recap.prods')"></small>
							<span v-if="cart && cart.length">
								{{ cart.length }}
							</span>
						</span>
						<span class="recapbox__totals">
							<span class="recapbox__totals__label" v-html="$t('admin.cart.recap.totals')"></span>
							<span class="recapbox__totals__value">
								{{ total }} &euro;
							</span>
						</span>
					</div>
					<span class="cartadmin__recap__btn confirm" @click="confirmCart">
						{{ $t('admin.cart.confirm') }}
					</span>
					<span class="cartadmin__recap__btn cancel" @click="emptyCart">
						{{ $t('admin.cart.emptycart') }}
					</span>
				</div>
			</template>
			<template v-else>
				<span class="cartadmin__empty">
					<span class="cartadmin__empty__title" v-html="$t('admin.cart.noprods.title')"></span>
					<span class="cartadmin__empty__text" v-html="$t('admin.cart.noprods.text')"></span>
				</span>
			</template>
		</div>
		<ModalEditQuantity />
		<ModalDeleteProd />
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
const _ = require('lodash');

import ProductRecap from './components/products/ProductRecap';
import ModalEditQuantity from "@/views/admin/components/products/ModalEditQuantity.vue";
import ModalDeleteProd from "@/views/admin/components/products/ModalDeleteProd.vue";

export default {
	name: 'cartadminAdmin',
	components: {
		ModalDeleteProd,
		ModalEditQuantity,
		ProductRecap
	},
	data() {
		return {
			cart: null,
			sections: null,
			plant: null,
			customer: null,
			total: 0
		}
	},
	methods: {
		updateQuantity(item) {
			let t = this.cart.filter(c => {
				return c.id == item.id;
			})
			if(t && t.length) {
				t[0].qta = item.qta;
			} else {
				this.cart.push({
					...this.selection,
					id: item.id,
					qta: item.qta,
					price: item.price,
					name: item.name
				});
			}
			
			// SAVE CART
			localStorage.setItem('cart', JSON.stringify(this.cart));
			
			EventBus.$emit('update-cart', this.cart);
		},
		removeProduct(id) {
			EventBus.$emit('confirm-delete-prod', id);
		},
		init(cart) {
			console.log('INIT', cart)
			if(cart && cart.length) {
				this.cart = cart;
			} else {
				console.log('NO CARRELLO')
				this.cart = JSON.parse(localStorage.getItem('cart'));
			}
			this.sections = _.groupBy(this.cart, ({ part }) => part)

			this.plant = JSON.parse(localStorage.getItem('current-plant'));
			this.customer = JSON.parse(localStorage.getItem('current-customer'));
			
			this.total = 0;
			if(this.cart && this.cart.length) {
				this.cart.forEach(c => {
					this.total += c.price * c.qta;
				})
			}

			console.log('CURRENT CART', this.cart);
		},
		confirmCart() {
			EventBus.$emit('confirm-cart');

		},
		emptyCart() {
			EventBus.$emit('empty-cart');
		}
	},
	mounted() {
		this.init();
		EventBus.$on('update-cart', (cart) => {
			console.log('UPDATE')
			this.init(cart);
		});
		
		EventBus.$on('update-cart-view', () => {
			this.init();
		})
		
		EventBus.$on('update-quantity', item => {
			this.updateQuantity(item);
		})
	}
}
</script>
<style lang="scss">
.cartadmin {
	height: 100vh;
	overflow: hidden;
	position: relative;
	&__head, &__content, &__utils {
		padding-left: var(--gutter);
		padding-right: var(--gutter);
	}
	&__head {
		position: absolute;
		top: 0;
		left: 0;
		@extend %flexCenterBetween;
		@include size(100%, calc(var(--supergutter) * 1.5));
		padding-top: var(--gutter);
		// padding-bottom: var(--minigutter);
		z-index: 10;
		&__title {
			@extend %flexCenterStart;
			width: max-content;
			margin-right: var(--gutter);
			&__icon {
				@extend %flexCenterStart;
				@include size(var(--icon),var(--icon));
				svg {
					display: block;
					@include size(75%,75%);
					fill: $color-red;
				}
			}
			&__label {
				display: block;
				width: calc(100% - var(--icon));
				@extend %text-24;
				font-family: $font-oswald;
				font-variation-settings: 'wght' 500;
				text-transform: uppercase;
			}
		}
		&__info {
			@extend %flexCenterStart;
			@include size(calc(100% - var(--smallicon)), var(--smallicon));
		}
		&__item {
			@extend %text-17;
			width: max-content;
			font-family: $font-oswald;
			font-variation-settings: 'wght' 500;
			margin-right: var(--gutter);

		}
	}
	&__content {
		@extend %flexStartBetween;
		width: 100%;
		height: calc(100% - ((var(--supergutter) * 1.5)));
		margin: calc(var(--supergutter) * 1.5) 0 var(--supergutter);
		overflow: auto;
	}
	&__list {
		display: block;
		width: 75%;
		margin: var(--gutter) 0;
		padding-right: var(--minigutter);
	}
	&__empty {
		display: block;
		width: 100%;
		padding-top: var(--supergutter);
		padding-bottom: calc(var(--supergutter) * 2);
		&__title, &__text {
			display: block;
			width: 100%;
			max-width: 300px;
			text-align: center;
			margin: 0 auto;
		}
		&__title {
			@extend %text-20;
			font-family: $font-oswald;
			font-variation-settings: 'wght' 500;
			color: $color-onyx;
			text-transform: uppercase;
			margin-bottom: var(--minigutter);
		}
		&__text {
			@extend %text-18;
			font-weight: 400;
		}
	}
	&__recap {
		display: block;
		width: 25%;
		margin: var(--gutter) 0;
		padding-left: var(--minigutter);
		background: transparent;
		.recapbox {
			display: block;
			width: 100%;
			margin-bottom: var(--minigutter);
			&__title,
			&__list {
				display: block;
				width: 100%;
			}
			&__title {
				@extend %text-24;
				font-family: $font-oswald;
				color: $color-black;
				text-transform: uppercase;
				border-bottom: 1px dotted $color-onyx;
				padding-bottom: var(--thirdgutter);
				margin-bottom: var(--thirdgutter);
			}
			&__totals {
				@extend %flexCenterBetween;
				@extend %text-22;
				font-family: $font-oswald;
				color: $color-black;
				border-top: 1px dotted $color-onyx;
				padding-top: var(--thirdgutter);
				margin-top: var(--thirdgutter);
				margin-bottom: var(--gutter);
			}
			&__list {
				small, span {
					display: block;
					width: 100%;
				}
				small {
					@extend %text-14;
					color: rgba($color-onyx, 0.4);
				}
				span {
					margin-bottom: var(--thirdgutter);
				}
			}
		}
		&__btn {
			@extend %flexCenterCenter;
			@include size(100%, var(--smallinput));
			border-radius: var(--radius);
			color: $color-white;
			margin-bottom: var(--minigutter);
			@extend %text-15;
			font-weight: 600;
			text-transform: uppercase;
			cursor: pointer;
			&.confirm {
				background: $color-success;
			}
			&.cancel {
				background: transparent;
				color: $color-onyx;
			}
		}
	}
	&__section {
		display: block;
		background: $color-white;
		width: 100%;
		padding: var(--gutter);
		box-shadow: -3px 3px 5px rgba($color-onyx, 0.1);
		margin-bottom: var(--minigutter);
		border-radius: var(--radius);
		&__title {
			display: block;
			@extend %text-20;
			font-family: $font-oswald;
			font-weight: 500;
			margin-bottom: var(--gutter);
			text-transform: uppercase;
		}
		&__head {
			@extend %flexCenterStart;
			width: 100%;
			padding: 0 var(--gutter);
			border-bottom: 1px dotted rgba($color-onyx, 0.2);
			margin-bottom: var(--minigutter);
			&__col {
				display: block;
				height: var(--gutter);
				@extend %text-14;
				&.prod { width: 25%; }
				&.code { width: 25%; }
				&.qta { width: 25%; }
				&.price { width: 10%; }
				&.cta { width: 10%; }
			}
		}
		&__list {
			display: block;
			width: 100%;
			padding: 0 var(--gutter);
		}
	}
}
</style>
